import { FC, ReactNode } from 'react';
import { Tooltip } from 'antd';

interface IAssociatedEntityListTooltipProps {
	entityList: { name: string; id: number }[];
	children: ReactNode;
}

export const AssociatedEntityListTooltip: FC<IAssociatedEntityListTooltipProps> = ({ entityList, children }) => (
	<Tooltip
		title={
			entityList?.length
				? entityList.map((entity) => <div key={entity.id}>{`\u2022 ${entity.name} (#${entity.id})`}</div>)
				: undefined
		}
	>
		{children}
	</Tooltip>
);
