import { CSSProperties, PropsWithChildren, ReactNode } from 'react';
import dayjs from 'dayjs';
import { Input, InputProps, SelectProps, TooltipProps } from 'antd';
import { Rule } from 'antd/es/form';
import { NamePath } from 'antd/es/form/interface';
import { TextAreaProps } from 'antd/es/input';
import { Locale } from 'antd/es/locale';
import { RadioGroupButtonStyle, RadioGroupOptionType } from 'antd/es/radio';
import { DirectionType } from 'antd/lib/config-provider';
import { NoUndefinedRangeValueType } from 'rc-picker/lib/PickerInput/RangePicker';

// ! types
export enum ESupportedLanguages {
	ENGLISH = 'en',
	ARABIC = 'ar',
}

export type TEmptyFunction = () => void;

export type TInputElementVariants = typeof Input | typeof Input.Password | typeof Input.TextArea;

// * common typescript
/** Utility to make certain keys of a type required */
export type RequiredKeys<T, K extends keyof T> = Exclude<T, K> & Required<Pick<T, K>>;

/** Utility to make type nullable. Ex: (string | null) */
export type Nullable<T> = T | null;

export type WithChildren<T = object> = { children: ReactNode } & T;

export type WithKey<P = unknown> = P & { key?: string };

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] };

export type NonEmptyArray<T> = [T, ...T[]];

export type TRangeValue = Nullable<NoUndefinedRangeValueType<dayjs.Dayjs>>;

// ! enums
export enum EOnOffStatus {
	ONLINE = 'online',
	OFFLINE = 'offline',
}

export enum EStatus {
	ACTIVE = 'active',
	INACTIVE = 'inactive',
}

export enum EChoiceOption {
	YES = 'yes',
	NO = 'no',
}

export enum ESupportedCountriesIsoTwoCodes {
	KUWAIT = 'kw',
	SAUDI_ARABIA = 'sa',
	UAE = 'ae',
	PORTUGAL = 'pt',
}

//* Enum 'Filesize' from BE core_backend/src/internal/utils/multer.utils.ts
export enum EImgFileSize {
	DOCUMENT = 5242880, // 5 * 1024 * 1024 5Mb
	STORE = 1048576, // 1 * 1024 * 1024 1Mb
	VENDOR = 1048576, // 1 * 1024 * 1024 1Mb
	CATEGORY = 409600, // 400 * 1024  400Kb
	PRODUCT = 409600, // 400 * 1024 400Kb,
	VERTICAL = 512000, // 500 * 1024 500Kb,
	DEFAULT = 409600, // 400 * 1024 400Kb,
	BANNER = 512000, // 500 * 1024 400Kb,
	DEEP_LINK_IMAGE = 307200, // 300 * 1024 300Kb,
	COMPENSATION_REQUEST_IMAGE = 3145728, // 3 * 1024 * 1024 3Mb
	APP_COMPONENT = 1048576, // 200 * 1024  200Kb
}
export enum EFileType {
	JPEG = 'image/jpeg',
	JPG = 'image/jpg',
	PDF = 'application/pdf',
	PNG = 'image/png',
}

export enum EDeliveryMode {
	// PICK_UP = 'pick_up', // the customer picks up the order himself - Unnecessary for the time being.
	PLATFORM = 'platform', // the delivery is done by the platform through a driver assigned by the platform
	VENDOR = 'vendor', // the delivery is done directly by the vendor
	ZONE_DELIVERY_SERVICES = 'zone_delivery_services',
}

export enum EAssignmentMode {
	// NIL = 'nil', // The order is never assigned. - Unnecessary for the time being.
	AUTOMATIC = 'automatic', // The order is automatically assigned to the nearest driver
	MANUAL = 'manual', // The order is exclusively assigned by an operations team member
}

export enum EUserTypes {
	ADMIN = 'admin',
	CUSTOMER = 'customer',
	DRIVER = 'driver',
	GUEST = 'guest',
	INTEGRATION = 'integration',
	OPERATIONS = 'operations',
	STORE = 'store',
	SYSTEM = 'system',
	VENDOR = 'vendor',
}

export enum EVehicleTypes {
	CAR = 'car',
	MOTORCYCLE = 'motorcycle',
}

export type TShortWeekDay = 'ALL' | 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT' | 'SUN';

// ! interfaces
export interface IListResponse<T = any> {
	count: number;
	data: T[];
}

export interface ISupportedLanguage {
	name: string;
	flag: string;
	iso_two_code: ESupportedLanguages;
	defaultDirection: DirectionType;
	locale: Locale;
}

export interface ITabItem {
	key: string;
	label: ReactNode;
	icon?: ReactNode;
	children?: ReactNode;
	style?: CSSProperties;
	disabled?: boolean;
}

export interface ISelectOption {
	label: ReactNode;
	value: number | string;
	disabled?: boolean;
	filterableLabel?: string;
	description?: ReactNode; // extra info
}

export interface IRadioOption {
	label: ReactNode;
	value: number | string | boolean;
}

export interface IFormItem extends PropsWithChildren {
	key: NamePath;
	label?: ReactNode;
	extra?: React.ReactNode;
	disabled?: boolean;
	initialValue?: unknown;
	placeholder?: string;
	tooltip?: ReactNode | TooltipProps;
	validationsRules?: Rule[];
	inputSelectionOptions?: ISelectOption[];
	radioOptions?: IRadioOption[];
	inputElement?: TInputElementVariants;
	textAreaProps?: TextAreaProps;
	inputProps?: InputProps;
	selectProps?: SelectProps;
	optionType?: RadioGroupOptionType;
	buttonStyle?: RadioGroupButtonStyle;
}

export interface IFilterItem {
	text: ReactNode;
	value: string | number | boolean;
}
