import { EAppConfigurationPage } from 'pages/HomeAppConfiguration/types';

export const PREVIEW_MODE_SEARCH_PARAM = 'view_mode';

// ! enums & types

export enum EBannerTypeTabs {
	HIGHLIGHTED_HOME = 'highlighted_home',
	HIGHLIGHTED_ACCOUNT = 'highlighted_account', // HOME 3.0 TO DROP
	PROMOTIONAL_SWIMLANE = 'promotional_swimlane',
}

export type TBannersPageType = EAppConfigurationPage.HUB | EAppConfigurationPage.ACCOUNT;

// ! interfaces
export interface IBannerListPageLayoutProps {
	pageType: TBannersPageType;
}
