import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppBrand } from 'hooks';
import { DEFAULT_DESCRIPTIONS_PROPS } from 'configs/common';
import { Nullable } from 'types/common';
import { EDeepLinkPathPages, IDeepLinkConfig } from './types';
import { CopyToClipBoard } from 'components/CopyToClipBoard';
import styles from './DeepLinkInput.module.css';
import {
	DEEP_LINK_CONFIG_MAP,
	DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG,
	DEEP_LINK_SELECT_OPTIONS_WITH_GROUPS,
} from './config';
import { Button, Descriptions, Flex, Select, SelectProps, Typography as T } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

interface IDeepLinkInputProps {
	hasDeleteButton?: boolean;
	value?: string;
	onChange?: (value: string) => void;
}

export const DeepLinkInput: FC<IDeepLinkInputProps> = ({ hasDeleteButton = false, value, onChange }) => {
	const { brand } = useAppBrand();

	const { t: tDeepLinks } = useTranslation('deep-links');

	// ! states
	const [linkParams, setLinkParams] = useState<Record<string, string | number | undefined>>({});
	const [selectedGoTo, setSelectedGoTo] = useState<Nullable<EDeepLinkPathPages>>(null);

	// ! memos
	const selectedGoToConfig = useMemo<Nullable<IDeepLinkConfig>>(() => {
		if (!selectedGoTo) return null;

		return DEEP_LINK_CONFIG_MAP[selectedGoTo];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedGoTo]);

	const deepLinkBaseUrl = useMemo(() => `https://${brand}.app`, [brand]);

	const finalDeepLink = useMemo<string>(() => {
		if (!selectedGoTo) return value || '';

		let pathSuffix: string = selectedGoTo;

		if (selectedGoToConfig?.params) {
			const regExp = /\{([^}]+)\}/g;
			pathSuffix = pathSuffix.replace(regExp, (match, key) => `${linkParams[key] || match}`);
		}

		return `${deepLinkBaseUrl}${pathSuffix}`;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [linkParams, selectedGoToConfig]);

	const canShowDeepLinkResult = useMemo(() => {
		if (!selectedGoToConfig) return false;

		// if is a deep link and there aren't required params to be filled
		if (!Array.isArray(selectedGoToConfig?.params)) return true;

		// if there is required params to filled, validate them
		const allInputParamsFilled = Object.values(linkParams).every((linkValue) => !!linkValue);

		return allInputParamsFilled && Object.keys(linkParams).length === selectedGoToConfig.params.length;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [linkParams, selectedGoToConfig]);

	// ! handlers
	const handleRedirectToChange = (newPath: EDeepLinkPathPages) => {
		setSelectedGoTo(newPath);
		setLinkParams({});
	};

	const redirectToSelectFilterFn: SelectProps['filterOption'] = (inputValue, option) => {
		if (!option?.value) return false;

		const lowercaseSearchText = inputValue.toLowerCase();

		const translationKey = DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[option.value as EDeepLinkPathPages];
		const lowercaseOptionLabel = tDeepLinks(`options.${translationKey}`).toLowerCase();

		return lowercaseOptionLabel.includes(lowercaseSearchText);
	};

	const onParamInputChange = (value: string | Nullable<number>, key: string) => {
		setLinkParams((prev) => {
			return {
				...prev,
				[key]: value || undefined,
			};
		});
	};

	const onDelete = () => {
		if (!hasDeleteButton) return;

		// reset states
		setLinkParams({});
		setSelectedGoTo(null);
		onChange?.('');
	};

	// ! effects
	// * on mount
	useEffect(() => {
		const currentValue = (value || '').replace(deepLinkBaseUrl, '');

		const allValidResults = Object.values(EDeepLinkPathPages)
			//
			.map<[EDeepLinkPathPages, Nullable<RegExpExecArray>]>((path) => {
				// Convert path to regex pattern
				const regexString = path
					.replace(/\?/g, '\\?') // escape "?"
					.replace(/&/g, '\\&') // escape "&"
					.replace(/=/g, '\\=') // escape "="
					.replace(/{(\w+)}/g, '(?<$1>[^&]+)'); // capture values
				// Full string match
				const regex = new RegExp(`^${regexString}$`, 'i');
				// Apply regex
				const regexResult = regex.exec(currentValue);
				// Return/save result
				return [path, regexResult];
			})
			// filter/remove the null values
			.filter((result) => result[1] !== null)
			// sort items by value length (descending order)
			.sort((a, b) => b[1]!.length - a[1]!.length);

		if (!allValidResults.length) return;

		const longestMatch = allValidResults[0] as [EDeepLinkPathPages, RegExpExecArray];

		const params = longestMatch[1].groups;
		if (params) setLinkParams(params);

		const path = longestMatch[0];
		setSelectedGoTo(path);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// * if deep link, send the final state when valid
	useEffect(() => {
		if (!finalDeepLink.length || !canShowDeepLinkResult || finalDeepLink === value) return;

		onChange?.(finalDeepLink);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [canShowDeepLinkResult, finalDeepLink]);

	// ! render
	return (
		<Flex
			vertical
			gap='small'
			className='w-100'
		>
			{hasDeleteButton && (
				<Button
					size='small'
					icon={<DeleteOutlined />}
					disabled={!selectedGoTo}
					className={styles.delete_btn}
					onClick={onDelete}
				/>
			)}

			<Descriptions
				{...DEFAULT_DESCRIPTIONS_PROPS}
				bordered
				styles={{
					label: { width: '200px', minWidth: '180px' },
					content: { maxWidth: 1 }, // Note: workaround to Antd styles to respect ellipses on the final link (CopyToClipBoard component)
				}}
			>
				{/* GO TO */}
				<Descriptions.Item label={tDeepLinks('labels.go_to')}>
					<Select<EDeepLinkPathPages>
						showSearch
						className='w-100'
						popupClassName={styles.redirect_to_select_dropdown}
						value={selectedGoTo}
						options={DEEP_LINK_SELECT_OPTIONS_WITH_GROUPS}
						optionRender={(option) => (
							<Flex vertical>
								{option.data.label}
								<T.Text
									type='secondary'
									className={styles.option_description}
								>
									{option.data.description}
								</T.Text>
							</Flex>
						)}
						placeholder={tDeepLinks('placeholders.go_to')}
						filterOption={redirectToSelectFilterFn}
						onChange={handleRedirectToChange}
					/>
				</Descriptions.Item>

				{/* PARAMS */}
				{selectedGoToConfig?.params?.map(({ key, slug, InputEl }) => (
					<Descriptions.Item
						key={slug}
						label={tDeepLinks(`labels.${slug}`)}
						style={{ backgroundColor: 'white' }}
					>
						<InputEl
							className='w-100'
							value={linkParams[key]}
							placeholder={tDeepLinks(`placeholders.${slug}`)}
							onChange={(value: string | Nullable<number>) => onParamInputChange(value, key)}
						/>
					</Descriptions.Item>
				))}

				{/* RESULT */}
				{canShowDeepLinkResult && (
					<Descriptions.Item label={tDeepLinks('labels.result').toUpperCase()}>
						<CopyToClipBoard>{finalDeepLink}</CopyToClipBoard>
					</Descriptions.Item>
				)}
			</Descriptions>
		</Flex>
	);
};
