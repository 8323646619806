import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNewHttpClient } from 'hooks';
import {
	transformIGenericVendorToIVendorOption,
	transformIVendorOptionToIVendorSwimlane,
	transformIVendorSwimlaneDetailsToIVendorSwimlane,
} from './helpers';
import { FORM_VALIDATORS } from 'utils/FormValidators';
import { DEFAULT_USER_INTERACTION_DEBOUNCE, debounce } from 'utils/debounce';
import { BANNERS_API, VENDOR_API } from 'configs/api';
import { SWIMLANE_VENDORS_MAX_LENGTH } from './configs';
import { EVendorStatus, IGenericVendor, ISwimlanePayload } from 'types/api';
import { IListResponse } from 'types/common';
import { IManageSwimlaneVendorsProps, ISelectedSwimlaneVendors, IVendorOption } from './types';
import CustomDrawer from 'components/CustomDrawer';
import SortableList from 'components/SortableList';
import styles from './ManageSwimlaneVendors.module.css';
import { Alert, App, Button, Col, Empty, Flex, Form, Image, List, Select, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import FormItem from 'antd/es/form/FormItem';
import { CloseOutlined } from '@ant-design/icons';
import noImgLogo from 'assets/images/no-image.svg';

const ManageSwimlaneVendors: FC<IManageSwimlaneVendorsProps> = ({
	open,
	onClose,
	bannerId,
	vendors,
	onSaveVendors,
}) => {
	const { message } = App.useApp();

	const { t: tBanners } = useTranslation('banners');
	const { t: tCommon } = useTranslation('common');

	const [form] = useForm();
	const FORM_ID = `manage_swimlane_vendors_${bannerId}`;

	// ! http clients
	const vendorsHttpClient = useNewHttpClient<IListResponse<IGenericVendor>>();
	const swimlanePayloadClient = useNewHttpClient<ISwimlanePayload>();

	// ! state
	const [selectedVendors, setSelectedVendors] = useState<ISelectedSwimlaneVendors[]>([]);

	// ! memo
	const listParams = useMemo(() => ({ status: EVendorStatus.APPROVED }), []);
	const initialVendors = useMemo(() => transformIVendorSwimlaneDetailsToIVendorSwimlane(vendors), [vendors]);

	const vendorNonAddedOptionList = useMemo(() => {
		const list = vendorsHttpClient.response?.data ?? [];

		return list
			.filter((vendor) => !selectedVendors.find((selectedVendor) => selectedVendor.id === vendor.id))
			.map(transformIGenericVendorToIVendorOption)
			.sort((a, b) => a.id - b.id);
	}, [selectedVendors, vendorsHttpClient.response?.data]);

	const handleVendorSearch = (value?: string) => {
		const searchValue = value?.trim() || undefined; // never send an empty string

		return vendorsHttpClient.request({
			requestConfig: VENDOR_API.genericList(searchValue, listParams),
			displayErrorMsg: false,
		});
	};

	// ! effects
	useEffect(() => {
		setSelectedVendors(transformIVendorSwimlaneDetailsToIVendorSwimlane(vendors));
		handleVendorSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// ! handlers
	const handleOnChange = (vendors: ISelectedSwimlaneVendors[]) => {
		setSelectedVendors(vendors);
	};

	const debouncedHandleSearch = debounce(handleVendorSearch, DEFAULT_USER_INTERACTION_DEBOUNCE);

	// * on form submit
	const onFormSubmit = () => {
		const payloadData: ISwimlanePayload = {
			id: bannerId,
			swimlane_vendors: transformIVendorOptionToIVendorSwimlane(selectedVendors),
		};

		// * update and create
		swimlanePayloadClient.request({
			requestConfig: BANNERS_API.updateSwimlaneVendors(+bannerId, payloadData),
			successCallback: () => {
				message.success(tBanners('manage_vendors_drawer.messages.success.update_swimlane'), 3);
				onSaveVendors();
				onClose();
			},
		});
	};

	const addVendor = () => {
		const selectedVendor = form.getFieldValue('vendorId');

		const vendor = vendorNonAddedOptionList.find((option) => selectedVendor === option.id);

		if (!vendor) return;

		form.setFieldValue('vendorId', null);

		setSelectedVendors([...selectedVendors, vendor] as ISelectedSwimlaneVendors[]);

		debouncedHandleSearch();
	};

	const onClearAll = () => {
		setSelectedVendors([]);
	};

	const onDeleteVendor = (selectedVendor: IVendorOption) => {
		const removedSelectedVendor = selectedVendors.filter((vendor) => selectedVendor.id !== vendor.id);

		setSelectedVendors(removedSelectedVendor);
	};

	const handleOnClose = () => {
		onClose();
		form.resetFields();
		setSelectedVendors(initialVendors);
	};

	// ! render
	return (
		<CustomDrawer
			open={open}
			size='large'
			title={tBanners('manage_vendors_drawer.title', { id: bannerId })}
			footer={
				<Flex justify='center'>
					<Button
						type='primary'
						htmlType='submit'
						form={FORM_ID}
						loading={swimlanePayloadClient.isLoading}
					>
						{tCommon('action_buttons.save')}
					</Button>
				</Flex>
			}
			onClose={handleOnClose}
		>
			<Form
				form={form}
				id={FORM_ID}
				layout='vertical'
				scrollToFirstError
				name='swimlane_vendors_form'
				key='swimlane_vendors_form'
				className={styles.form}
				onFinish={onFormSubmit}
			>
				{/* List Vendors */}
				<div className='flex flex-column h-100'>
					<>
						<Flex
							gap='middle'
							align='center'
							justify='space-between'
							wrap='wrap'
							className='w-100'
						>
							<Form.Item
								name='vendorId'
								label={tBanners('entity.vendors')}
								className={styles.vendor_input}
							>
								<Select
									allowClear
									showSearch
									loading={vendorsHttpClient.isLoading}
									placeholder={tBanners('manage_vendors.placeholder.select_vendor')}
									onSearch={debouncedHandleSearch}
									onClear={debouncedHandleSearch}
									options={vendorNonAddedOptionList}
									filterOption={(inputValue, option) => {
										return !!option?.key.toLocaleLowerCase()?.includes(inputValue.toLowerCase());
									}}
								/>
							</Form.Item>
							<Flex
								gap='small'
								align='center'
								className={styles.align_buttons}
							>
								<Button
									onClick={addVendor}
									disabled={selectedVendors.length === SWIMLANE_VENDORS_MAX_LENGTH}
								>
									{tBanners('manage_vendors.button.add')}
								</Button>
								<Button
									type='dashed'
									onClick={onClearAll}
									disabled={selectedVendors.length === 0}
								>
									{tBanners('manage_vendors.button.clear_all')}
								</Button>
							</Flex>
						</Flex>
						{selectedVendors.length === SWIMLANE_VENDORS_MAX_LENGTH && (
							<Alert
								showIcon
								message={tBanners('manage_vendors_drawer.messages.error.max_vendors', {
									max: SWIMLANE_VENDORS_MAX_LENGTH,
								})}
								type='warning'
								className={styles.alert}
							/>
						)}
					</>

					<div className='flex-fill overflow-scroll-y w-100'>
						{!selectedVendors || selectedVendors.length === 0 ? (
							<Empty
								image={Empty.PRESENTED_IMAGE_SIMPLE}
								description={tBanners('manage_vendors.placeholder.no_vendors_position')}
							/>
						) : (
							<FormItem
								name='vendors'
								initialValue={selectedVendors ?? []}
								rules={[
									FORM_VALIDATORS.ARRAY_LENGTH({
										maxLength: SWIMLANE_VENDORS_MAX_LENGTH,
									}),
								]}
								dependencies={['vendorId', 'vendors']}
							>
								<List className={styles.list}>
									<SortableList<ISelectedSwimlaneVendors>
										items={selectedVendors}
										onChange={handleOnChange}
										renderItem={(vendor) => {
											return (
												<SortableList.Item
													id={vendor.id}
													key={vendor.key}
												>
													<Flex
														align='center'
														gap='small'
													>
														<Typography.Text className={styles.sort_index}>
															{selectedVendors.indexOf(vendor) + 1}
														</Typography.Text>
														<SortableList.DragHandle />

														{vendor.image && (
															<Col flex='none'>
																<Image
																	src={vendor.image}
																	width={30}
																	height={30}
																	loading='lazy'
																	preview={false}
																	fallback={noImgLogo}
																	alt='element-img'
																	className={styles.image_col}
																/>
															</Col>
														)}

														<Typography.Text className='w-100'>
															{vendor.label}
														</Typography.Text>

														<Button
															icon={<CloseOutlined />}
															type='text'
															className={styles.remove_button}
															onClick={() => onDeleteVendor(vendor)}
														/>
													</Flex>
												</SortableList.Item>
											);
										}}
									/>
								</List>
							</FormItem>
						)}
					</div>
				</div>
			</Form>
		</CustomDrawer>
	);
};

export default ManageSwimlaneVendors;
