import { getArrayLengthValidator } from './getArrayLengthValidator';
import { getGreaterEqualThanValidator } from './getGreaterEqualThanValidator';
import { getGreaterThanZeroValidator } from './getGreaterThanZeroValidator';
import { getLesserEqualThanValidator } from './getLesserEqualThanValidator';
import { getValidateFileSizeValidator } from './getValidateFileSizeValidator';
import { getValidateFileTypeValidator } from './getValidateFileTypeValidator';
import { Rule } from 'antd/es/form';

enum EGenericFormValidators {
	REQUIRED = 'REQUIRED',
	REQUIRED_WHITE_SPACE = 'REQUIRED_WHITE_SPACE',
	NOT_REQUIRED_WHITE_SPACE = 'NOT_REQUIRED_WHITE_SPACE',

	// * NUMBER
	NUMBER_LESSER_EQUAL_THAN = 'NUMBER_LESSER_EQUAL_THAN',
	NUMBER_GREATER_EQUAL_THAN = 'NUMBER_GREATER_EQUAL_THAN',
	NUMBER_GREATER_THAN_ZERO = 'NUMBER_GREATER_THAN_ZERO',

	// * ARRAY
	ARRAY_LENGTH = 'ARRAY_LENGTH',

	// * IMAGES
	IMAGE_FILE_TYPES = 'IMAGE_FILE_TYPES',
	IMAGE_FILE_SIZE = 'IMAGE_FILE_SIZE',
}

// ! MAP
export const FORM_VALIDATORS: Record<EGenericFormValidators, (...args: any[]) => Rule> = {
	[EGenericFormValidators.REQUIRED]: () => ({ required: true }),
	[EGenericFormValidators.REQUIRED_WHITE_SPACE]: () => ({ required: true, whitespace: true }),
	[EGenericFormValidators.NOT_REQUIRED_WHITE_SPACE]: () => ({ required: false, whitespace: true }),
	// * NUMBER
	[EGenericFormValidators.NUMBER_LESSER_EQUAL_THAN]: getLesserEqualThanValidator,
	[EGenericFormValidators.NUMBER_GREATER_EQUAL_THAN]: getGreaterEqualThanValidator,
	[EGenericFormValidators.NUMBER_GREATER_THAN_ZERO]: getGreaterThanZeroValidator,
	// * ARRAY
	[EGenericFormValidators.ARRAY_LENGTH]: getArrayLengthValidator,
	// * IMAGES
	[EGenericFormValidators.IMAGE_FILE_TYPES]: getValidateFileTypeValidator,
	[EGenericFormValidators.IMAGE_FILE_SIZE]: getValidateFileSizeValidator,
};
