import { ImageCell } from 'configs/common';
import TranslatedLabel from 'components/TranslatedLabel';
import { IGenericEntity } from 'pages/HomeAppConfiguration/components/GenericTableInput';
import { TableColumnsType } from 'antd';

export const ALL_VERTICAL_MODAL_COLUMNS: TableColumnsType<IGenericEntity> = [
	{
		key: 'id',
		title: <TranslatedLabel i18nKey='table.headers.id' />,
		dataIndex: 'id',
	},
	{
		key: 'name',
		title: <TranslatedLabel i18nKey='table.headers.name' />,
		dataIndex: 'name',
	},
	{
		key: 'name_ar',
		title: <TranslatedLabel i18nKey='table.headers.name_ar' />,
		dataIndex: 'name_ar',
	},
	{
		key: 'image',
		title: <TranslatedLabel i18nKey='table.headers.image' />,
		width: 82,
		render: (_, { image }) => <ImageCell imageSrc={image} />,
	},
];
