import { ICountry } from 'types/api';
import { UniqueIdentifier } from '@dnd-kit/core';

export const PREVIEW_MODE_SEARCH_PARAM = 'view_mode';

// ! enums & types
export enum EApplicationSchemaTypes {
	HOME = 'home',
	HUB = 'hub',
	ACCOUNT = 'account',
	STORES_LIST = 'stores_list',
}

export enum EAppConfigurationPage {
	HOME = 'home',
	ALL_SERVICES = 'all-services',
	//
	HUB = 'hub',
	ACCOUNT = 'account',
	//
	VERTICALS = 'verticals',
	MOBILE_COMPONENTS = 'mobile-components',
	DEEP_LINKS = 'deep-links',
}

export enum EMyAccountGenericEntryComponentType {
	ABOUT_US = 'about_us',
	CARI_UNLIMITED = 'cari_unlimited',
	CARI_PRIZE = 'cari_prize',
	LANGUAGE = 'language',
	MANAGE_ADDRESSES = 'manage_addresses',
	SAVED_CARDS = 'saved_cards',
}

export enum ESchemaComponentType {
	// Home
	BILLBOARD_BANNER = 'billboard_banner',
	GENERIC_VENDOR_SWIMLANE = 'generic_vendor_swimlane',
	HERO_VENDOR_SWIMLANE = 'hero_vendor_swimlane',
	MATRIX_VENDOR_SWIMLANE = 'matrix_vendor_swimlane',
	TRACKABLE_ORDERS = 'trackable_orders',
	VERTICALS_MOSAIC = 'verticals_mosaic',
	BANNER = 'banner',
	BANNER_CAROUSEL = 'banner_carousel',

	// Hub
	VERTICALS_LIST = 'verticals_list',
	WIDE_VENDOR_SWIMLANE = 'wide_vendor_swimlane',

	// Account
	PROFILE_SECTION = 'profile_section',
	MY_ORDERS_SWIMLANE = 'my_orders_swimlane',
	MY_FAVOURITES_SWIMLANE = 'my_favourites_swimlane',
	MY_ACCOUNT_GENERIC_ENTRY = 'my_account_generic_entry',
}

export interface ISchemaComponentBillBoardBanner extends ISchemaComponent {
	type: ESchemaComponentType.BILLBOARD_BANNER;
	image: string;
	image_ar: string;
	action: string; // Action or link associated with the banner
}

// ! interfaces
export interface ISchemaComponentWithImage {
	id: number;
	image: string;
	image_ar: string;
}

export interface ISchemaComponentVerticalMosaic extends ISchemaComponent {
	type: ESchemaComponentType.VERTICALS_MOSAIC;
	items_per_row: number; // Number of items to display per row
	verticals: ISchemaComponentWithImage[];
}

export interface ISchemaComponentTrackableOrders extends ISchemaComponent {
	type: ESchemaComponentType.TRACKABLE_ORDERS;
	title: string; // Title for the orders section
}

export interface ISchemaComponentHeroSwimlane extends ISchemaComponent {
	type: ESchemaComponentType.HERO_VENDOR_SWIMLANE;
	title: string;
	background_color: string;
	vendors: ISchemaComponentWithImage[];
}

export interface ISchemaComponentGenericSwimlane extends ISchemaComponent {
	type: ESchemaComponentType.GENERIC_VENDOR_SWIMLANE;
	title: string;
	image: string;
	image_ar: string;
	vendors: ISchemaComponentWithImage[];
}

export interface ISchemaComponentMatrixVendorSwimlane extends ISchemaComponent {
	type: ESchemaComponentType.MATRIX_VENDOR_SWIMLANE;
	title: string;
	items_per_column: number;
	vendors: ISchemaComponentWithImage[];
}

export interface ISchemaComponentBannerCarousel extends ISchemaComponent {
	type: ESchemaComponentType.BANNER_CAROUSEL;
	rotation_period: number;
	banners: ISchemaComponentWithImage[];
}

export interface ISchemaComponentBanner extends ISchemaComponent {
	type: ESchemaComponentType.BANNER;
	image: string;
	image_ar: string;
	action: string;
}

export interface ISchemaComponentVerticalsList extends ISchemaComponent {
	type: ESchemaComponentType.VERTICALS_LIST;
	display_count: number;
}

export interface ISchemaComponentWideVendorSwimlane extends ISchemaComponent {
	type: ESchemaComponentType.WIDE_VENDOR_SWIMLANE;
	title: string;
	title_ar: string;
	vendors: ISchemaComponentWithImage[];
}

export interface ISchemaComponentProfileSection extends ISchemaComponent {
	type: ESchemaComponentType.PROFILE_SECTION;
	title: string;
	title_ar: string;
}

export interface ISchemaComponentMyOrdersSwimlane extends ISchemaComponent {
	type: ESchemaComponentType.MY_ORDERS_SWIMLANE;
	title: string;
	title_ar: string;
	items_per_column: number;
}

export interface ISchemaComponentMyFavouritesSwimlane extends ISchemaComponent {
	type: ESchemaComponentType.MY_FAVOURITES_SWIMLANE;
	title: string;
	title_ar: string;
	items_per_column: number;
}

export interface ISchemaComponentMyAccountGenericEntry extends ISchemaComponent {
	type: ESchemaComponentType.MY_ACCOUNT_GENERIC_ENTRY;
	title: string;
	title_ar: string;
	description: string;
	description_ar: string;
	action?: string;
	account_component_name: EMyAccountGenericEntryComponentType;
}

export type ISchemaComponentItem =
	| ISchemaComponentBillBoardBanner
	| ISchemaComponentVerticalMosaic
	| ISchemaComponentTrackableOrders
	| ISchemaComponentHeroSwimlane
	| ISchemaComponentGenericSwimlane
	| ISchemaComponentMatrixVendorSwimlane
	| ISchemaComponentBannerCarousel
	| ISchemaComponentBanner
	| ISchemaComponentVerticalsList
	| ISchemaComponentWideVendorSwimlane
	| ISchemaComponentProfileSection
	| ISchemaComponentMyOrdersSwimlane
	| ISchemaComponentMyFavouritesSwimlane
	| ISchemaComponentMyAccountGenericEntry;

export interface ISchemaComponent {
	id: UniqueIdentifier;
	type: ESchemaComponentType;
	name: string;

	position: number;
}

export interface IAppSchema {
	id: UniqueIdentifier;
	country_id: ICountry['id'];
	name: string;
}

export interface IHomeConfigSchemaGet {
	schema: ISchemaComponentItem[];
}
