import { ID_COLUMN_WIDTH } from 'configs/common';
import { EAllowedMenuAssignmentMode, EAllowedMenuBookingType, EMenuPurpose, EMenuType } from 'types/api';
import { IFilterItem, ISelectOption } from 'types/common';
import { IMenuTableData } from './types';
import TranslatedLabel from 'components/TranslatedLabel';
import { AssociatedEntityListTooltip } from 'pages/Vendors/AssociatedEntityListTooltip';
import { Flex, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { MenuOutlined } from '@ant-design/icons';

export const ALLOWED_MENU_BOOKING_TYPE_SELECT_OPTIONS: ISelectOption[] = Object.values(EAllowedMenuBookingType).map(
	(type) => ({
		value: type,
		label: (
			<TranslatedLabel
				nameSpace='menu-editor'
				i18nKey={`shared.enum_allowed_booking_type.${type}`}
			/>
		),
	})
);

export const ALLOWED_MENU_ASSIGNMENT_MODE_SELECT_OPTIONS: ISelectOption[] = Object.values(
	EAllowedMenuAssignmentMode
).map((mode) => ({
	value: mode,
	label: (
		<TranslatedLabel
			nameSpace='menu-editor'
			i18nKey={`shared.enum_allowed_assignment_mode.${mode}`}
		/>
	),
}));

// * Menu Type
const getMenuTypeTranslatedLabel = (type: EMenuType) => (
	<TranslatedLabel
		upperCase
		nameSpace='menu-editor'
		i18nKey={`shared.enum_menu_type.${type}`}
	/>
);

export const MENU_TYPE_SELECT_OPTIONS: ISelectOption[] = Object.values(EMenuType).map((value) => ({
	value,
	label: getMenuTypeTranslatedLabel(value),
}));

export const MENU_TYPE_FILTER_OPTIONS: IFilterItem[] = Object.values(EMenuType).map((value) => ({
	value,
	text: getMenuTypeTranslatedLabel(value),
}));

export const getMenuTypeTag = (type: EMenuType) => <Tag>{getMenuTypeTranslatedLabel(type)}</Tag>;

// * Menu Purpose
const getMenuPurposeTranslatedLabel = (purpose: EMenuPurpose) => (
	<TranslatedLabel
		nameSpace='menu-editor'
		i18nKey={`shared.enum_menu_purpose.${purpose}`}
	/>
);

export const MENU_PURPOSE_SELECT_OPTIONS: ISelectOption[] = Object.values(EMenuPurpose).map((value) => ({
	value,
	label: getMenuPurposeTranslatedLabel(value),
}));

export const MENU_PURPOSE_FILTER_OPTIONS: IFilterItem[] = Object.values(EMenuPurpose).map((value) => ({
	value,
	text: getMenuPurposeTranslatedLabel(value),
}));

export const getMenuPurposeTag = (purpose: EMenuPurpose) => (
	<Tag color='magenta'>{getMenuPurposeTranslatedLabel(purpose)}</Tag>
);

// * Menu Table Columns
export const DEFAULT_MENU_TABLE_COLUMNS: ColumnsType<IMenuTableData> = [
	{
		key: 'id',
		title: (
			<TranslatedLabel
				nameSpace='vendors'
				i18nKey='vendor_details.tabs.menus.shared.entity.id'
			/>
		),
		dataIndex: 'id',
		width: ID_COLUMN_WIDTH,
	},
	{
		key: 'name',
		title: (
			<TranslatedLabel
				nameSpace='vendors'
				i18nKey='vendor_details.tabs.menus.shared.entity.name'
			/>
		),
		dataIndex: 'name',
	},
	{
		key: 'name_ar',
		title: (
			<TranslatedLabel
				nameSpace='vendors'
				i18nKey='vendor_details.tabs.menus.shared.entity.name_ar'
			/>
		),
		dataIndex: 'name_ar',
	},
	{
		key: 'type',
		title: (
			<TranslatedLabel
				nameSpace='vendors'
				i18nKey='vendor_details.tabs.menus.shared.entity.type'
			/>
		),
		width: 110,
		render: (_, { type }) => getMenuTypeTag(type),
		filterMultiple: false,
		filters: MENU_TYPE_FILTER_OPTIONS,
	},
	{
		key: 'purpose',
		title: (
			<TranslatedLabel
				nameSpace='vendors'
				i18nKey='vendor_details.tabs.menus.shared.entity.purpose'
			/>
		),
		width: 110,
		render: (_, { purpose }) => getMenuPurposeTag(purpose),
		filterMultiple: false,
		filters: MENU_PURPOSE_FILTER_OPTIONS,
	},
	{
		key: 'associated_branches',
		title: (
			<TranslatedLabel
				nameSpace='vendors'
				i18nKey='vendor_details.tabs.menus.shared.entity.associated_branches'
			/>
		),
		width: 110,
		render: (_, { stores }) => (
			<AssociatedEntityListTooltip entityList={stores}>
				<Flex gap='small'>
					<MenuOutlined />
					{stores.length}
				</Flex>
			</AssociatedEntityListTooltip>
		),
	},
];
