import { useTranslation } from 'react-i18next';
import { EBannerType } from 'types/api';
import PageLayout from 'components/PageLayout';
import BannerList from 'pages/Banners/components/BannerList';
import { EBannerTypeTabs } from 'pages/Banners/types';
import { BannerAddCommands } from '../HubAppConfiguration/BannerAddCommands';
import { PageHeader } from '@ant-design/pro-layout';

export const DeprecatingHighLightedHome = () => {
	const { t: tCommon } = useTranslation();

	// ! render
	return (
		<PageLayout header={<PageHeader title={tCommon(`nav_items.highlighted_home`)} />}>
			<BannerList
				key={EBannerType.HIGHLIGHTED_HOME}
				type={EBannerTypeTabs.HIGHLIGHTED_HOME}
				tableExtra={<BannerAddCommands type={EBannerTypeTabs.HIGHLIGHTED_HOME} />}
			/>
		</PageLayout>
	);
};
