import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { VISUAL_DIVIDER_ROUTE } from 'configs/DividerRoute';
import { APP_PERMISSIONS, SUPER_ADMIN_PERMISSION } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import { EAppConfigurationPage } from './types';
import ListParamsRouteWrapper from 'components/ListParamsRouteWrapper';
import { BannerListPageLayout } from 'pages/Banners';
import { AddEditBannerProvider } from 'pages/Banners/AddEditBannerProvider';
import { BANNER_DETAILS_ROUTE } from 'pages/Banners/routes.banners';
import { EBannerTypeTabs } from 'pages/Banners/types';
import { VerticalGroupList } from 'pages/HomeAppConfiguration/VerticalGroupList/VerticalGroupList';
import { VERTICAL_ROUTES } from 'pages/Verticals/routes/routes.verticals';
import { HIGHLIGHTED_HOME_ROUTE } from './DeprecatingHighLightedHome/routes.highlightedHome';
import { HubAppConfigurationLayout } from './HubAppConfiguration/HubAppConfigurationLayout';
import { HUB_LIST_TABS_ROUTES } from './HubAppConfiguration/routes.hub';
import { EHubTabs } from './HubAppConfiguration/types';
import { MobileComponentsList } from './MobileComponentsList/MobileComponentsList';
import {
	ApartmentOutlined,
	BarsOutlined,
	BuildOutlined,
	FileSyncOutlined,
	HomeOutlined,
	LinkOutlined,
	UserOutlined,
} from '@ant-design/icons';

// ! lazy imports
const HomeAppConfiguration = lazy(() => import('./HomeLayout/HomeAppConfiguration'));
const DeepLinksPage = lazy(() => import('./DeepLinksPage'));

export const APPLICATION_CONFIGURATION_ROUTES: TRouteObject = {
	path: 'application-configuration',
	element: <AddEditBannerProvider />,
	handle: {
		showOnNav: true,
		isNavigateable: false,
		icon: <FileSyncOutlined />,
		translationKey: 'application_configuration',
		permissions: [],
	},
	children: [
		// ! Home
		{
			path: EAppConfigurationPage.HOME,
			handle: {
				showOnNav: true,
				translationKey: 'home_screen_management',
				icon: <HomeOutlined />,
				mustHaveABs: { CORE_6756_NEW_HOME_2: 'ACTIVE' },
				permissions: [APP_PERMISSIONS.app_management.home_configuration.view],
			},
			element: <HomeAppConfiguration />,
		},

		// ! All Services
		{
			path: EAppConfigurationPage.ALL_SERVICES,
			handle: {
				showOnNav: true,
				translationKey: 'all_services_screen',
				icon: <BarsOutlined />,
				mustHaveABs: { CORE_6756_NEW_HOME_2: 'ACTIVE' },
				permissions: [APP_PERMISSIONS.app_management.vertical_groups.view],
			},
			element: <VerticalGroupList />,
		},

		{
			handle: {
				...VISUAL_DIVIDER_ROUTE.handle,
				permissions: [SUPER_ADMIN_PERMISSION],
			},
		},

		// ! Hub Layout
		{
			path: EAppConfigurationPage.HUB,
			element: (
				<ListParamsRouteWrapper key='hub_banners'>
					<HubAppConfigurationLayout />
				</ListParamsRouteWrapper>
			),
			handle: {
				showOnNav: true,
				translationKey: 'hub_screen',
				icon: <ApartmentOutlined />,
				permissions: [APP_PERMISSIONS.app_management.hub_configuration.view],
			},
			children: [
				{
					index: true,
					element: <Navigate to={EHubTabs.HUB_SCHEMA} />,
				},
				...HUB_LIST_TABS_ROUTES,
				{
					path: ':bannerType',
					children: [
						{
							index: true,
							element: <BannerListPageLayout pageType={EAppConfigurationPage.HUB} />,
						},
						BANNER_DETAILS_ROUTE,
					],
				},
			],
		},

		// ! Account Layout
		{
			path: EAppConfigurationPage.ACCOUNT,
			element: <ListParamsRouteWrapper key='account_banners' />,
			handle: {
				showOnNav: true,
				translationKey: 'account_screen',
				icon: <UserOutlined />,
				permissions: [APP_PERMISSIONS.app_management.banners.view],
				mustHaveABs: { CORE_6756_NEW_HOME_3: 'ACTIVE' },
			},
			children: [
				{
					index: true,
					element: <Navigate to={EBannerTypeTabs.HIGHLIGHTED_ACCOUNT} />,
				},
				{
					path: ':bannerType',
					children: [
						{
							index: true,
							element: <BannerListPageLayout pageType={EAppConfigurationPage.ACCOUNT} />,
						},
						BANNER_DETAILS_ROUTE,
					],
				},
			],
		},

		{
			handle: {
				...VISUAL_DIVIDER_ROUTE.handle,
				permissions: [APP_PERMISSIONS.app_management.banners.view],
			},
		},

		HIGHLIGHTED_HOME_ROUTE,

		// ! Verticals
		VERTICAL_ROUTES,

		// ! Mobile Components
		{
			path: EAppConfigurationPage.MOBILE_COMPONENTS,
			handle: {
				showOnNav: true,
				translationKey: EAppConfigurationPage.MOBILE_COMPONENTS.replaceAll('-', '_'),
				icon: <BuildOutlined />,
				mustHaveABs: { CORE_6756_NEW_HOME_2: 'ACTIVE' },
				permissions: [APP_PERMISSIONS.app_management.app_component.view],
			},
			element: <MobileComponentsList withTitle={true} />,
		},

		// ! Deep links
		{
			path: EAppConfigurationPage.DEEP_LINKS,
			handle: {
				showOnNav: true,
				translationKey: 'deep_links',
				icon: <LinkOutlined />,
				mustHaveABs: { CORE_6756_NEW_HOME_2: 'ACTIVE' },
				permissions: [APP_PERMISSIONS.app_management.deep_link.generate],
			},
			element: <DeepLinksPage />,
		},
	],
};
