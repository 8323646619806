import { FC, Key, useEffect, useState } from 'react';
import { useNewHttpClient } from 'hooks';
import { transformIGenericListToISegmentOption } from '../helper';
import { DEFAULT_USER_INTERACTION_DEBOUNCE, debounce } from 'utils/debounce';
import { STORES_API } from 'configs/api';
import { EBranchStatus, IGenericBranch } from 'types/api';
import { IListResponse } from 'types/common';
import { ISegmentOption } from '../types';
import { IStoresSelectProps } from './types';
import { Select } from 'antd';

const StoresSelect: FC<IStoresSelectProps> = ({ onlyApproved = false, ...props }) => {
	const [options, setOptions] = useState<ISegmentOption[]>();

	// ! http clients
	const fetchStoresHttpClient = useNewHttpClient<IListResponse<IGenericBranch>>();

	// ! handlers
	const handleSearch = (value?: string) => {
		const searchValue = value?.trim() || undefined; // never send an empty string

		const requestConfig = STORES_API.genericList(searchValue, {
			status: onlyApproved ? EBranchStatus.APPROVED : [],
		});

		return fetchStoresHttpClient.request({
			requestConfig,
			displayErrorMsg: false,
			successCallback: ({ data }) => {
				const resultingOptions = transformIGenericListToISegmentOption(data);

				const optionSet = (options ?? []).concat(resultingOptions).reduce((acc, item) => {
					acc[item.value] = item;
					return acc;
				}, {} as Record<Key, ISegmentOption>);

				setOptions(Object.values(optionSet));
			},
		});
	};

	const debouncedHandleSearch = debounce(handleSearch, DEFAULT_USER_INTERACTION_DEBOUNCE);

	// ! effects
	useEffect(() => {
		handleSearch();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// ! render
	return (
		<Select
			allowClear
			showSearch
			maxTagCount={3}
			options={options}
			popupMatchSelectWidth={false}
			loading={fetchStoresHttpClient.isLoading}
			filterOption={(inputValue, opt) => !!opt?.key?.includes(inputValue.toLowerCase())}
			onSearch={debouncedHandleSearch}
			{...props}
		/>
	);
};

export default StoresSelect;
