import { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, useForceRefreshData } from 'hooks';
import { ALL_SERVICES_API } from 'configs/api/applicationConfiguration/allServicesConfiguration';
import { STATUS_FILTERS_CONFIG } from 'configs/common';
import { APP_PERMISSIONS } from 'configs/permissions';
import { IVerticalGroupListResponse, IVerticalGroupListTableData, IVerticalGroupPatchPayload } from './types';
import ActionsMenu, { IActionMenuItem, getActionButtonsColumnWidth } from 'components/ActionsMenu';
import DisplayNameAndNameAr from 'components/DisplayNameAndNameAr';
import PageContainer from 'components/PageContainer';
import StatusSwitch from 'components/StatusSwitch';
import TableWrapper from 'components/Table';
import { EListViewMode, SegmentedViewModeToggler, useViewMode } from 'pages/Banners/useViewMode';
import VerticalCard from './VerticalCard/VerticalCard';
import { VerticalGroupCreateEditDrawer } from './VerticalGroupCreateEditDrawer/VerticalGroupCreateEditDrawer';
import { IVerticalGroupCreateEditDrawerHandle } from './VerticalGroupCreateEditDrawer/types';
import { VerticalGroupPreview } from './VerticalGroupPreview';
import { useVerticalGroupController } from './useVerticalGroupController';
import { Button, Empty, Flex } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { PageHeader } from '@ant-design/pro-layout';

const ALL_SERVICE_LIST_REQUEST = ALL_SERVICES_API.list();

export const VerticalGroupList = () => {
	const { t: tCommon } = useTranslation();
	const { t: tAllServicesConfiguration } = useTranslation('all-services-configuration');

	const verticalGroupCtrl = useVerticalGroupController();
	const { hasPermission } = useAuth();

	const { viewMode } = useViewMode();

	// ! refs
	const verticalSortModalRef = useRef<IVerticalGroupCreateEditDrawerHandle>(null);

	// ! states
	const { forceRefreshData, refreshingData } = useForceRefreshData();

	// ! memos
	const canEdit = hasPermission(APP_PERMISSIONS.app_management.vertical_groups.update);
	const canDelete = hasPermission(APP_PERMISSIONS.app_management.vertical_groups.delete);

	const columns = useMemo<ColumnsType<IVerticalGroupListTableData>>(
		() => [
			{
				title: tCommon('table.headers.id'),
				dataIndex: 'id',
			},
			{
				title: tCommon('table.headers.name'),
				render: (_, { title, title_ar }) => (
					<DisplayNameAndNameAr
						name={title}
						nameAr={title_ar}
					/>
				),
			},
			{
				key: 'position',
				title: tAllServicesConfiguration('table.headers.position'),
				dataIndex: 'position',
				sorter: true,
			},
			{
				key: 'status',
				title: tCommon('table.headers.status'),
				render: (_, record) => (
					<StatusSwitch
						status={record.status}
						disabled={!canEdit}
						onChange={(newStatus) =>
							verticalGroupCtrl
								.toggleGroupStatus(record.id, newStatus)
								.then(refreshingData)
								.catch(() => {})
						}
					/>
				),
				filterMultiple: false,
				filters: STATUS_FILTERS_CONFIG,
			},
			{
				key: 'actions',
				title: tCommon('table.headers.actions'),
				width: getActionButtonsColumnWidth(2),
				fixed: 'right',
				render: (_, record) => {
					const actions: IActionMenuItem[] = [
						// EDIT
						{
							type: 'button',
							title: tCommon('action_buttons.edit'),
							icon: <EditOutlined />,
							disabled: !canEdit,
							actionCb: () => verticalSortModalRef.current?.open(record),
						},
						// DELETE
						{
							type: 'button',
							title: tCommon('action_buttons.delete'),
							icon: <DeleteOutlined />,
							disabled: !canDelete,
							actionCb: () => handleDeleteGroup(record.id),
						},
					];
					return <ActionsMenu actions={actions} />;
				},
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[canEdit, canDelete]
	);

	// ! handlers
	const handleOnCreateGroup = () => {
		verticalSortModalRef.current?.open();
	};

	const handleDeleteGroup = (id: number) => {
		verticalGroupCtrl.deleteGroup(id)?.then(refreshingData);
	};

	// ! render
	return (
		<PageContainer
			pageHeader={
				<PageHeader
					title={tCommon('nav_items.all_services_screen')}
					extra={[
						<SegmentedViewModeToggler key='segment_toggler' />,
						<Button
							key='add'
							type='primary'
							onClick={handleOnCreateGroup}
							disabled={!canEdit}
						>
							{tAllServicesConfiguration('action_buttons.add_group')}
						</Button>,
					]}
				/>
			}
		>
			{viewMode === EListViewMode.TABLE && (
				<TableWrapper<IVerticalGroupListTableData, IVerticalGroupListResponse>
					columns={columns}
					requestConfig={ALL_SERVICE_LIST_REQUEST}
					transformDataToTableData={(data) => ({ key: data.id, ...data })}
					refetchData={forceRefreshData}
					disableSearch={false}
					searchParam='title'
					expandable={{
						expandedRowRender: ({ verticals }, index) => (
							<Flex
								gap='middle'
								justify={verticals.length ? 'start' : 'center'}
								className='overflow-scroll-x'
							>
								{verticals.map((vertical) => (
									<VerticalCard
										{...vertical}
										key={`${index} ${vertical.id}`}
									/>
								))}

								{!verticals.length && <Empty />}
							</Flex>
						),

						rowExpandable: (record) => !!record.verticals.length,
					}}
				/>
			)}

			{viewMode === EListViewMode.PREVIEW && (
				<VerticalGroupPreview
					handleOpenVerticalGroup={(value: IVerticalGroupListResponse) => {
						verticalSortModalRef.current?.open(value as IVerticalGroupListTableData);
					}}
					forceRefreshData={forceRefreshData}
				/>
			)}

			<VerticalGroupCreateEditDrawer
				ref={verticalSortModalRef}
				onConfirm={(data: IVerticalGroupPatchPayload) => {
					if (data.id) {
						return verticalGroupCtrl.patch(data).then(refreshingData);
					} else {
						return verticalGroupCtrl.createGroup(data).then(refreshingData);
					}
				}}
				confirmLoading={
					verticalGroupCtrl.patchHttpClient.isLoading || verticalGroupCtrl.createHttpClient.isLoading
				}
			/>
		</PageContainer>
	);
};
