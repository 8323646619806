import { BANNER_DETAILS_ROUTE } from 'pages/Banners/routes.banners';
import { DeprecatingHighLightedHome } from './DeprecatingHighLightedHome';
import { HighlightOutlined } from '@ant-design/icons';

// todo - TO REMOVE ON DEV
export const HIGHLIGHTED_HOME_ROUTE = {
	path: 'highlighted_home',
	handle: {
		icon: <HighlightOutlined />,

		showOnNav: true,
		translationKey: 'highlighted_home',

		mustHaveABs: { HIGHLIGHTED_HOME_ROUTE_TEMPORARY_HIDE: 'ACTIVE' },
	},
	children: [
		{
			index: true,
			element: <DeprecatingHighLightedHome />,
		},
		BANNER_DETAILS_ROUTE,
	],
};
