import { PropsWithChildren } from 'react';
import { useVendor } from 'hooks';
import { VENDOR_MENU_API } from 'configs/api';
import { MenuControllerContext, useMenu } from './useMenu';
import { useMenuParamId } from './useMenuParamId';

const VendorMenuProvider = ({ children }: PropsWithChildren) => {
	const { vendorId } = useVendor();
	const { id: menuId } = useMenuParamId();

	const menuController = useMenu({
		getMenuConfig: (search?: string) => VENDOR_MENU_API.getMenu(vendorId, +menuId, search),
		addProductConfig: (categoryId, data) => VENDOR_MENU_API.addProduct(vendorId, +menuId, categoryId, data),
		addCategoryConfig: (data) => VENDOR_MENU_API.addCategory(vendorId, +menuId, data),
		updateTreeNodeStatusConfig: (data) => VENDOR_MENU_API.updateTreeNodeStatus(vendorId, +menuId, data),
		deleteTreeNodeConfig: (data) => VENDOR_MENU_API.deleteTreeNode(vendorId, +menuId, data),
		patchMenuConfig: (data) => VENDOR_MENU_API.patchMenu(vendorId, +menuId, data),
		sortMenuEntityConfig: (payload) => VENDOR_MENU_API.sortMenuEntity(vendorId, +menuId, payload),
		toggleProductSnoozeConfig: null,
		getFetchAssociatedBranchesConfig: () => VENDOR_MENU_API.getStores(vendorId, +menuId),
	});

	return <MenuControllerContext.Provider value={menuController}>{children}</MenuControllerContext.Provider>;
};

export { VendorMenuProvider };
