import { createContext, useContext, useMemo } from 'react';
import type { CSSProperties, PropsWithChildren } from 'react';
import { ISortableItemContext, ISortableItemProps } from './types';
import { Button } from 'antd';
import { ButtonProps } from 'antd/es/button';
import { HolderOutlined } from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const SortableItemContext = createContext<ISortableItemContext>({
	attributes: {},
	listeners: undefined,
	ref() {},
});

export const SortableItem = ({ children, id }: PropsWithChildren<ISortableItemProps>) => {
	const { attributes, isDragging, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
		id,
	});

	const context = useMemo(
		() => ({
			attributes,
			listeners,
			ref: setActivatorNodeRef,
		}),
		[attributes, listeners, setActivatorNodeRef]
	);

	const style: CSSProperties = {
		transform: CSS.Translate.toString(transform),
		transition,

		...(isDragging
			? {
					opacity: 0.2,
			  }
			: {}),
	};

	// ! render
	return (
		<SortableItemContext.Provider value={context}>
			<div
				ref={setNodeRef}
				style={style}
				className='SortableItem'
			>
				{children}
			</div>
		</SortableItemContext.Provider>
	);
};

export const DragHandle = ({ type, ...buttonProps }: ButtonProps) => {
	const { attributes, listeners, ref } = useContext(SortableItemContext);

	// ! render
	return (
		<Button
			ref={ref}
			type={type || 'text'}
			className='DragHandle'
			icon={<HolderOutlined />}
			{...attributes}
			{...listeners}
			{...buttonProps}
		/>
	);
};
