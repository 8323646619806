import { ReactNode, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetFormValues, useNewHttpClient, useSearchDataList } from 'hooks';
import {
	getBannerPositionsOptionList,
	getStoresOptionList,
	getVendorsOptionList,
	getVerticalsOptionList,
} from './helpers';
import { BANNERS_API, STORES_API, VENDOR_API, VERTICALS_API } from 'configs/api';
import { DEFAULT_MODAL_PROPS } from 'configs/common';
import { APP_PERMISSIONS } from 'configs/permissions';
import {
	BANNER_HAS_INTERACTION_TYPE,
	BANNER_HAS_POSITION,
	BANNER_INTERACTION_TYPE_OPTIONS,
	BANNER_MOBILE_TARGET_OPTIONS,
	BANNER_TYPE_DISABLED,
	SELECT_TYPE_OPTIONS_BY_BANNER_TYPE,
} from './configs';
import {
	EBannerInteractionType,
	EBannerType,
	EBranchStatus,
	EVendorStatus,
	EVerticalType,
	IBanner,
	ICreateUpdateBannerPayload,
	IGenericBranch,
	IGenericVendor,
	IGenericVertical,
} from 'types/api';
import { EStatus, IListResponse, ISelectOption, Nullable, TEmptyFunction } from 'types/common';
import { IAddEditBannerModalHandle, IAddEditBannerModalProps } from './types';
import { DEFAULT_BANNER_TYPE_BY_BANNER_TAB } from 'pages/Banners/configs';
import { App, Form, Input, Modal, Select } from 'antd';
import { useWatch } from 'antd/es/form/Form';

const AddEditBannerModal = forwardRef<IAddEditBannerModalHandle, IAddEditBannerModalProps>(({ ...props }, ref) => {
	const { message } = App.useApp();
	const { getFormValues } = useGetFormValues();

	const { t: tBanners } = useTranslation('banners');

	// ! refs
	const refreshingDataAfterSuccessRef = useRef<TEmptyFunction>();

	// * form
	const [form] = Form.useForm<ICreateUpdateBannerPayload>();

	const NAME_MAX_LENGTH = 200;
	const TITLE_MAX_LENGTH = 200;

	// ! watchers
	const bannerTypeFormValue = useWatch('type', form);
	const bannerInteractionTypeFormValue = useWatch('interaction_type', form);

	// * stores search
	const {
		list: stores,
		loading: fetchingStores,
		onSearch: onStoresSearch,
	} = useSearchDataList<IGenericBranch>({
		configHttp: (search) => STORES_API.genericList(search, { status: EBranchStatus.APPROVED }),
		searchRequestOnlyOnDemand: true,
		permissions: [APP_PERMISSIONS.vendor.store.view],
	});

	// * vendors search
	const {
		list: vendors,
		loading: fetchingVendors,
		onSearch: onVendorsSearch,
	} = useSearchDataList<IGenericVendor>({
		configHttp: (search) => VENDOR_API.genericList(search, { status: EVendorStatus.APPROVED }),
		searchRequestOnlyOnDemand: true,
		permissions: [APP_PERMISSIONS.vendor.view],
	});

	// * verticals search
	const {
		list: verticals,
		loading: fetchingVerticals,
		onSearch: onVerticalsSearch,
	} = useSearchDataList<IGenericVertical>({
		configHttp: (search) =>
			VERTICALS_API.genericList(search, {
				type: [EVerticalType.INDEPENDENT, EVerticalType.PARENT, EVerticalType.CHILD],
				status: EStatus.ACTIVE,
			}),
		searchRequestOnlyOnDemand: true,
		permissions: [APP_PERMISSIONS.app_management.vertical.view],
	});

	// ! http client
	const createHttpClient = useNewHttpClient();
	const editBannerHttpClient = useNewHttpClient();

	const fetchVendorHttpClient = useNewHttpClient<IListResponse<IGenericVendor>>();
	const fetchStoreHttpClient = useNewHttpClient<IListResponse<IGenericBranch>>();
	const fetchVerticalHttpClient = useNewHttpClient<IListResponse<IGenericVertical>>();

	// ! states
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [banner, setBanner] = useState<Nullable<IBanner>>(null);

	const isEditing = !!banner?.id;

	const isLoading =
		createHttpClient.isLoading ||
		editBannerHttpClient.isLoading ||
		fetchingStores ||
		fetchingVendors ||
		fetchingVerticals;

	// ! handlers
	const onCloseModal = () => {
		setIsOpen(false);
		setBanner(null);
	};

	const onAfterCloseModal = () => {
		form.resetFields();
		refreshingDataAfterSuccessRef.current = undefined;
	};

	const onFormSubmit = async () => {
		const formValues = await getFormValues(form);
		if (!formValues) return;

		if (isEditing) {
			return editBannerHttpClient.request({
				requestConfig: BANNERS_API.update(banner.id, formValues),
				successCallback: () => {
					message.success(
						tBanners(
							formValues.type === EBannerType.SWIMLANE
								? 'success_messages.update_swimlane'
								: 'success_messages.update'
						),
						3
					);
					refreshingDataAfterSuccessRef.current?.();
					onCloseModal();
				},
			});
		} else {
			return createHttpClient.request({
				requestConfig: BANNERS_API.create(formValues),
				successCallback: () => {
					message.success(
						tBanners(
							formValues.type === EBannerType.SWIMLANE
								? 'success_messages.create_swimlane'
								: 'success_messages.create'
						),
						3
					);
					refreshingDataAfterSuccessRef.current?.();
					onCloseModal();
				},
			});
		}
	};

	// ! effects
	useEffect(() => {
		if (!bannerInteractionTypeFormValue) return;

		// fill store || vendor || verticals list
		switch (bannerInteractionTypeFormValue) {
			case EBannerInteractionType.STORE: {
				onStoresSearch();
				break;
			}
			case EBannerInteractionType.VENDOR: {
				onVendorsSearch();
				break;
			}
			case EBannerInteractionType.VERTICAL: {
				onVerticalsSearch();
				break;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bannerInteractionTypeFormValue]);

	// ! memos
	const vendorsOptionList = useMemo(() => {
		const vendorToConcat = fetchVendorHttpClient.response?.data ?? [];

		return getVendorsOptionList(vendors, vendorToConcat);
	}, [fetchVendorHttpClient.response, vendors]);

	const storesOptionList = useMemo(() => {
		const storesToConcat = fetchStoreHttpClient.response?.data ?? [];

		return getStoresOptionList(stores, storesToConcat);
	}, [fetchStoreHttpClient.response, stores]);

	const verticalsOptionList = useMemo(() => {
		const verticalsToConcat = fetchVerticalHttpClient.response?.data ?? [];

		return getVerticalsOptionList(verticals, verticalsToConcat);
	}, [fetchVerticalHttpClient.response, verticals]);

	const modalTitle = useMemo(() => {
		return isEditing
			? tBanners(`edit_modal.${bannerTypeFormValue}_title`, { id: banner?.id })
			: tBanners(`add_banner.${bannerTypeFormValue}_title`);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [banner, bannerTypeFormValue, isEditing]);

	const typeSelectFieldDisabled = useMemo(
		() => isEditing || BANNER_TYPE_DISABLED[bannerTypeFormValue],
		[bannerTypeFormValue, isEditing]
	);

	const bannerInteractionOptions = useMemo(() => {
		return BANNER_INTERACTION_TYPE_OPTIONS.filter(
			(item) =>
				bannerTypeFormValue !== EBannerType.HIGHLIGHTED_ACCOUNT ||
				item.value !== EBannerInteractionType.VERTICAL
		);
	}, [bannerTypeFormValue]);

	// ! ref hook
	useImperativeHandle(
		ref,
		() => ({
			open: (refreshFunction, bannerTypeTab, bannerInfo) => {
				setIsOpen(true);

				refreshingDataAfterSuccessRef.current = refreshFunction;

				if (bannerInfo) setBanner(bannerInfo);

				const isEditing = !!bannerInfo;

				form.setFieldsValue({
					// Default Values
					type: DEFAULT_BANNER_TYPE_BY_BANNER_TAB[bannerTypeTab],
					// Edit Values
					...bannerInfo,
				});

				if (isEditing) {
					// request vendor
					if (bannerInfo?.vendor_id) {
						fetchVendorHttpClient.request({
							requestConfig: VENDOR_API.genericList(undefined, {
								ids: [bannerInfo.vendor_id.toString()],
							}),
						});
					}

					// request store
					if (bannerInfo?.store_id) {
						fetchStoreHttpClient.request({
							requestConfig: STORES_API.genericList(undefined, {
								ids: [bannerInfo.store_id.toString()],
							}),
						});
					}

					// request vertical
					if (bannerInfo?.vertical_id) {
						fetchVerticalHttpClient.request({
							requestConfig: VERTICALS_API.genericList(undefined, {
								id: [bannerInfo.vertical_id.toString()],
							}),
						});
					}
				}
			},
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	// ! render
	const OptionalFieldEByInteractionType: Record<EBannerInteractionType, ReactNode> = {
		[EBannerInteractionType.EXTERNAL_LINK]: (
			// Redirection Link
			<Form.Item
				name='redirection_link'
				initialValue=''
				label={tBanners('entity.redirection_link')}
				rules={[
					{ required: true, whitespace: true },
					{ type: 'string', max: 100 },
				]}
			>
				<Input placeholder={tBanners('placeholders.redirection_link')} />
			</Form.Item>
		),
		[EBannerInteractionType.INTERNAL_LINK]: (
			// Mobile App Target
			<Form.Item
				name='mobile_app_target'
				label={tBanners('entity.mobile_app_target')}
				rules={[{ required: true }]}
			>
				<Select
					options={BANNER_MOBILE_TARGET_OPTIONS}
					placeholder={tBanners('placeholders.mobile_app_target')}
				/>
			</Form.Item>
		),
		[EBannerInteractionType.INFO]: null,
		[EBannerInteractionType.STORE]: (
			// Store ID
			<Form.Item
				name='store_id'
				label={tBanners('entity.store')}
				rules={[{ required: true }]}
			>
				<Select
					allowClear
					showSearch
					filterOption={false}
					loading={fetchingStores}
					placeholder={tBanners('placeholders.store')}
					options={storesOptionList}
					onSearch={onStoresSearch}
					onSelect={() => onStoresSearch()}
				/>
			</Form.Item>
		),
		[EBannerInteractionType.VENDOR]: (
			// Vendor ID
			<Form.Item
				name='vendor_id'
				label={tBanners('entity.vendor')}
				rules={[{ required: true }]}
			>
				<Select
					allowClear
					showSearch
					filterOption={false}
					loading={fetchingVendors}
					placeholder={tBanners('placeholders.store')}
					options={vendorsOptionList}
					onSearch={onVendorsSearch}
					onSelect={() => onVendorsSearch()}
				/>
			</Form.Item>
		),
		[EBannerInteractionType.VERTICAL]:
			bannerTypeFormValue !== EBannerType.HIGHLIGHTED_ACCOUNT ? (
				// Vertical ID
				<Form.Item
					name='vertical_id'
					label={tBanners('entity.vertical')}
					rules={[{ required: true }]}
				>
					<Select
						allowClear
						showSearch
						filterOption={false}
						loading={fetchingVerticals}
						placeholder={tBanners('placeholders.vertical')}
						options={verticalsOptionList}
						onSearch={onVerticalsSearch}
						onSelect={() => onVerticalsSearch()}
					/>
				</Form.Item>
			) : null,
	};

	const BANNER_POSITION_OPTIONS: Record<EBannerType, ISelectOption[]> = {
		[EBannerType.HIGHLIGHTED_HOME]: getBannerPositionsOptionList(20),
		[EBannerType.HIGHLIGHTED_ACCOUNT]: getBannerPositionsOptionList(20),
		[EBannerType.PROMOTIONAL]: getBannerPositionsOptionList(100),
		[EBannerType.SWIMLANE]: getBannerPositionsOptionList(100),
	};

	return (
		<Modal
			open={isOpen}
			forceRender
			{...DEFAULT_MODAL_PROPS}
			title={modalTitle}
			confirmLoading={isLoading}
			onOk={onFormSubmit}
			onCancel={onCloseModal}
			afterClose={onAfterCloseModal}
		>
			<Form
				form={form}
				layout='vertical'
				name='add_edit_banner_form'
			>
				{/* Type */}
				<Form.Item
					name='type'
					label={tBanners('entity.type')}
					rules={[{ required: true }]}
				>
					<Select
						disabled={typeSelectFieldDisabled}
						options={SELECT_TYPE_OPTIONS_BY_BANNER_TYPE[bannerTypeFormValue]}
					/>
				</Form.Item>

				{/* Names */}
				<Form.Item
					name='name'
					initialValue=''
					label={tBanners('entity.name')}
					rules={[
						{ required: true, whitespace: true },
						{ type: 'string', max: NAME_MAX_LENGTH },
					]}
				>
					<Input
						placeholder={tBanners('placeholders.name')}
						maxLength={NAME_MAX_LENGTH}
						showCount
					/>
				</Form.Item>
				<Form.Item
					name='name_ar'
					initialValue=''
					label={tBanners('entity.name_ar')}
					rules={[
						{ required: true, whitespace: true },
						{ type: 'string', max: NAME_MAX_LENGTH },
					]}
				>
					<Input
						placeholder={tBanners('placeholders.name_ar')}
						maxLength={NAME_MAX_LENGTH}
						showCount
					/>
				</Form.Item>

				{/* Title */}
				{bannerTypeFormValue === EBannerType.SWIMLANE && (
					<>
						<Form.Item
							name='title'
							initialValue=''
							label={tBanners('entity.title')}
							rules={[
								{ required: true, whitespace: true },
								{ type: 'string', max: TITLE_MAX_LENGTH },
							]}
						>
							<Input
								placeholder={tBanners('placeholders.title')}
								maxLength={TITLE_MAX_LENGTH}
								showCount
							/>
						</Form.Item>

						<Form.Item
							name='title_ar'
							initialValue=''
							label={tBanners('entity.title_ar')}
							rules={[
								{ required: true, whitespace: true },
								{ type: 'string', max: TITLE_MAX_LENGTH },
							]}
						>
							<Input
								placeholder={tBanners('placeholders.title_ar')}
								maxLength={TITLE_MAX_LENGTH}
								showCount
							/>
						</Form.Item>
					</>
				)}

				{/* Positions */}
				{BANNER_HAS_POSITION[bannerTypeFormValue] && (
					<Form.Item
						name='position'
						label={tBanners('entity.position')}
						rules={[{ required: true }]}
						extra={tBanners('add_banner.info')}
					>
						<Select
							allowClear
							placeholder={tBanners('placeholders.position')}
							options={BANNER_POSITION_OPTIONS[bannerTypeFormValue]}
						/>
					</Form.Item>
				)}

				{/* Interaction Type */}
				{BANNER_HAS_INTERACTION_TYPE[bannerTypeFormValue] && (
					<>
						<Form.Item
							name='interaction_type'
							label={tBanners('entity.interaction_type')}
							rules={[{ required: true }]}
						>
							<Select
								options={bannerInteractionOptions}
								placeholder={tBanners('placeholders.interaction_type')}
								onChange={() => {
									// reset the optional fields that depend on the interaction type
									form.resetFields([
										'redirection_link',
										'mobile_app_target',
										'store_id',
										'vendor_id',
										'vertical_id',
									]);
								}}
							/>
						</Form.Item>

						{/* Optional Fields (by Interaction Type choice) */}
						{OptionalFieldEByInteractionType[bannerInteractionTypeFormValue]}
					</>
				)}
			</Form>
		</Modal>
	);
});

export default AddEditBannerModal;
