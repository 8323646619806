import { FC, useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from 'hooks';
import { LIST_DEFAULT_PARAMS } from 'configs/api';
import { APP_PERMISSIONS } from 'configs/permissions';
import { BANNER_LIST_TABS_ROUTES, BANNER_TYPE_TAB_BY_APP_PAGE } from './configs';
import { EBannerTypeTabs, IBannerListPageLayoutProps, PREVIEW_MODE_SEARCH_PARAM } from './types';
import { CustomTabs } from 'components/CustomTabs';
import { ListParamsContext } from 'components/ListParamsRouteWrapper/context/ListParamsContext';
import PageLayout from 'components/PageLayout';
import { EAppConfigurationPage } from 'pages/HomeAppConfiguration/types';
import { useAddEditBannerContext } from './AddEditBannerProvider';
import BannerList from './components/BannerList';
import { SegmentedViewModeToggler, useViewMode } from './useViewMode';
import { Button } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';

const BannerListPageLayout: FC<IBannerListPageLayoutProps> = ({ pageType = EAppConfigurationPage.HUB }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const { hasPermission } = useAuth();
	const { t: tBanners } = useTranslation('banners');
	const { listParamsRef } = useContext(ListParamsContext);
	const { viewMode } = useViewMode();

	const { openModal, refreshingData } = useAddEditBannerContext();

	const allowedTabList = BANNER_TYPE_TAB_BY_APP_PAGE[pageType];

	const { bannerType = allowedTabList[0] } = useParams<{ bannerType: EBannerTypeTabs }>();

	// ! states

	// ! handlers

	const onTabChange = (activeKey: EBannerTypeTabs) => {
		navigate({
			pathname: `../${activeKey}`,
			search: createSearchParams({ [PREVIEW_MODE_SEARCH_PARAM]: viewMode.toString() }).toString(),
		});
	};

	const onAddBannerBtnClick = () => {
		openModal(refreshingData, bannerType);
	};

	// ! memos
	const tabs = useMemo(
		() => BANNER_LIST_TABS_ROUTES.filter((tab) => allowedTabList.includes(tab.path)),
		[allowedTabList]
	);

	const pageHeaderExtra = useMemo(() => {
		const addBtn = (
			<Button
				key='add_banner_btn'
				type='primary'
				onClick={onAddBannerBtnClick}
				disabled={!hasPermission(APP_PERMISSIONS.app_management.banners.add)}
			>
				{bannerType === EBannerTypeTabs.PROMOTIONAL_SWIMLANE
					? tBanners('add_banner_swimlane.button')
					: tBanners('add_banner.button')}
			</Button>
		);

		return (
			<>
				<SegmentedViewModeToggler />

				{addBtn}
			</>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bannerType, viewMode, hasPermission]);

	// ! effects
	// reset saved search params from other tabs
	useEffect(() => {
		if (!listParamsRef?.current) return;

		// get path string without EBannerType part
		const tabUrlStart = location.pathname.replace(`/${bannerType}`, '');

		// remove saved search params from other tabs
		for (const tabKey in listParamsRef.current) {
			// ignore this tab and non tab items
			const isTabRoute = tabKey.startsWith(tabUrlStart);
			const isCurrentTab = tabKey === location.pathname;
			if (!isTabRoute || isCurrentTab) continue;

			// check if tab has saved params
			const savedParams = listParamsRef.current[tabKey];
			if (!savedParams) continue;

			// drop saved params to default params for table
			listParamsRef.current[tabKey] = { ...LIST_DEFAULT_PARAMS };
		}
	}, [location, listParamsRef, bannerType]);

	// ! render
	if (!bannerType) return null;

	return (
		<PageLayout
			header={
				<>
					<PageHeader
						title={tBanners(`list_pages.title.${pageType}`)}
						extra={pageHeaderExtra}
					/>

					<CustomTabs
						routes={tabs}
						activeKey={bannerType}
						defaultActiveKey={EBannerTypeTabs.HIGHLIGHTED_HOME}
						onTabChange={onTabChange}
					/>
				</>
			}
		>
			<BannerList
				key={bannerType}
				type={bannerType}
			/>
		</PageLayout>
	);
};

export default BannerListPageLayout;
