import { APP_PERMISSIONS } from 'configs/permissions';
import { TRouteObject } from 'configs/routes';
import { ESupportedCountriesIsoTwoCodes } from 'types/common';
import { EBranchTabsKeys } from './types';
import CITCIcon from 'components/CitcIcon';
import TranslatedLabel from 'components/TranslatedLabel';
import BranchTimingsTabContent from './Tabs/BranchTimingsTabContent';
import CitcBranchTabContent from './Tabs/CitcBranchTabContent/CitcBranchTabContent';
import DeliveryAreas from './Tabs/DeliveryAreas';
import InfoTabContent from './Tabs/Details';
import { VENDOR_BRANCH_MENUS_ROUTES } from './Tabs/Menus/routes.vendor-branch-menus';
import { ClockCircleOutlined, InfoOutlined, RadiusSettingOutlined } from '@ant-design/icons';

const branchTabsPathKeyPrefix = 'vendor_details.tabs.branches.branch_details.tabs';

export const BRANCH_DETAILS_TAB_ROUTES: Record<EBranchTabsKeys, TRouteObject> = {
	[EBranchTabsKeys.DETAILS]: {
		path: EBranchTabsKeys.DETAILS,
		element: <InfoTabContent />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='vendors'
					i18nKey='info.title'
					extra={{ keyPrefix: branchTabsPathKeyPrefix }}
				/>
			),
			icon: <InfoOutlined />,
			permissions: [APP_PERMISSIONS.vendor.store.view],
		},
	},
	[EBranchTabsKeys.MENUS]: {
		path: EBranchTabsKeys.MENUS,
		...VENDOR_BRANCH_MENUS_ROUTES,
	},
	[EBranchTabsKeys.TIMINGS]: {
		path: EBranchTabsKeys.TIMINGS,
		element: <BranchTimingsTabContent />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='vendors'
					i18nKey='timings.title'
					extra={{ keyPrefix: branchTabsPathKeyPrefix }}
				/>
			),
			icon: <ClockCircleOutlined />,
			permissions: [APP_PERMISSIONS.vendor.shift.view],
		},
	},
	[EBranchTabsKeys.DELIVERY_AREA]: {
		path: EBranchTabsKeys.DELIVERY_AREA,
		element: <DeliveryAreas />,
		handle: {
			label: (
				<TranslatedLabel
					nameSpace='vendors'
					i18nKey='delivery_area.title'
					extra={{ keyPrefix: branchTabsPathKeyPrefix }}
				/>
			),
			icon: <RadiusSettingOutlined />,
			permissions: [APP_PERMISSIONS.areas.view, APP_PERMISSIONS.vendor.store.delivery_area.view],
		},
	},
	[EBranchTabsKeys.CITC]: {
		path: EBranchTabsKeys.CITC,
		element: <CitcBranchTabContent />,
		handle: {
			icon: <CITCIcon />,
			label: (
				<TranslatedLabel
					nameSpace='vendors'
					i18nKey='citc.title'
					extra={{ keyPrefix: branchTabsPathKeyPrefix }}
				/>
			),
			permissions: [APP_PERMISSIONS.citc.view],
			country_white_list: [ESupportedCountriesIsoTwoCodes.SAUDI_ARABIA],
		},
	},
};

export const BRANCH_DETAILS_ROUTES: TRouteObject[] = Object.values(BRANCH_DETAILS_TAB_ROUTES);
