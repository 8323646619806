import { FC } from 'react';
import { EVerticalType } from 'types/api';
import { ISelectOption } from 'types/common';
import { EDeepLinkPathPages, IDeepLinkConfig } from './types';
import { SelectVendor, StoresSelect, VerticalsSelect } from 'components/GenericListSelectors';
import TranslatedLabel from 'components/TranslatedLabel';
import { CUSTOMER_STORE_OFFER_TYPE_SELECT_OPTIONS } from 'pages/Customers/configs';
import { MENU_PURPOSE_SELECT_OPTIONS } from 'pages/Vendors/VendorDetails/Tabs/Menu/config';
import { Input, InputNumber, InputProps, Select, SelectProps } from 'antd';
import { InputNumberProps } from 'antd/lib';

const REDIRECTION_TYPE_SELECT_OPTIONS: ISelectOption[] = ['external', 'internal'].map((type) => ({
	value: type,
	label: (
		<TranslatedLabel
			nameSpace='deep-links'
			i18nKey={`enums.redirection_type.${type}`}
		/>
	),
}));

// ! Default Input Params Components
const DefaultInputNumber: FC<InputNumberProps> = (props) => (
	<InputNumber
		min={1}
		{...props}
	/>
);

const WebViewLinkInputText: FC<{ onChange: (value: string) => void } & InputProps> = ({ onChange, ...props }) => (
	<Input
		onChange={(ev) => onChange?.(ev.target.value)}
		{...props}
	/>
);

const RegCodeInputText: FC<{ onChange: (value: string) => void } & InputProps> = ({ onChange, ...props }) => (
	<Input
		maxLength={32}
		onChange={(ev) => onChange?.(ev.target.value)}
		{...props}
	/>
);

const WebViewRedirectionTypSelect: FC<SelectProps> = (props) => (
	<Select
		options={REDIRECTION_TYPE_SELECT_OPTIONS}
		{...props}
	/>
);

const MenuPurposeSelect: FC<SelectProps> = (props) => (
	<Select
		options={MENU_PURPOSE_SELECT_OPTIONS}
		{...props}
	/>
);

const CustomerStoreOfferTypeSelect: FC<SelectProps> = (props) => (
	<Select
		options={CUSTOMER_STORE_OFFER_TYPE_SELECT_OPTIONS}
		{...props}
	/>
);

const VendorsSelector: FC<SelectProps> = (props) => (
	<SelectVendor
		onlyApproved
		{...props}
	/>
);

const StoresSelector: FC<SelectProps> = (props) => (
	<StoresSelect
		onlyApproved
		{...props}
	/>
);

// ! TRANSLATION KEYS CONFIG
export const DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG: Record<EDeepLinkPathPages, string> = {
	// Home
	[EDeepLinkPathPages.HOME_SCREEN]: 'home_screen',
	// All Services
	[EDeepLinkPathPages.ALL_SERVICES_SCREEN]: 'all_services_screen',
	// Hub Specific
	[EDeepLinkPathPages.HUB_SCREEN_GENERIC_SPECIFIC_VERTICAL]: 'hub_screen_generic_specific_vertical',
	[EDeepLinkPathPages.HUB_SCREEN_GENERIC_SPECIFIC_VERTICAL_AND_SUB_VERTICAL]:
		'hub_screen_generic_specific_vertical_and_sub_vertical',
	// [EDeepLinkPathPages.HUB_SCREEN_BOX_DELIVERY]: 'hub_screen_box_delivery', // ? TEMP HIDE
	// Store Details
	[EDeepLinkPathPages.STORE_DETAILS_BY_ID]: 'store_details_by_id',
	[EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID]: 'store_details_by_id_and_menu_id',
	[EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID_AND_CATEGORY_ID]:
		'store_details_by_id_and_menu_id_and_category_id',
	[EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID]:
		'store_details_by_id_and_menu_id_and_category_id_and_product_id',
	[EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_PURPOSE]: 'store_details_by_id_and_menu_purpose',
	[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE]: 'store_details_by_cam_reg_code',
	[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID]: 'store_details_by_cam_reg_code_and_menu_id',
	[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID_AND_CATEGORY_ID]:
		'store_details_by_cam_reg_code_and_menu_id_and_category_id',
	[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID]:
		'store_details_by_cam_reg_code_and_menu_id_and_category_id_and_product_id',
	[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_PURPOSE]:
		'store_details_by_cam_reg_code_and_menu_purpose',
	[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID]: 'store_details_by_vendor_id',
	[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID]: 'store_details_by_vendor_id_and_menu_id',
	[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID_AND_CATEGORY_ID]:
		'store_details_by_vendor_id_and_menu_id_and_category_id',
	[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID]:
		'store_details_by_vendor_id_and_menu_id_and_category_id_and_product_id',
	[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_PURPOSE]: 'store_details_by_vendor_id_and_menu_purpose',
	// Wallet
	[EDeepLinkPathPages.WALLET]: 'wallet',
	// Offers
	[EDeepLinkPathPages.OFFERS]: 'offers',
	[EDeepLinkPathPages.OFFERS_BY_OFFER_TYPE]: 'offers_by_offer_type',
	[EDeepLinkPathPages.OFFERS_BY_OFFER_TYPE_AND_OFFER_LABEL_ID]: 'offers_by_offer_type_and_offer_label_id',
	// Account
	[EDeepLinkPathPages.ACCOUNT_SCREEN]: 'account_screen',
	[EDeepLinkPathPages.ACCOUNT_FAVORITES_SCREEN]: 'account_favorites_screen',
	[EDeepLinkPathPages.ACCOUNT_ADDRESSES_SCREEN]: 'account_addresses_screen',
	[EDeepLinkPathPages.ACCOUNT_CARDS_SCREEN]: 'account_cards_screen',
	[EDeepLinkPathPages.ACCOUNT_CARI_UNLIMITED_SCREEN]: 'account_cari_unlimited_screen',
	[EDeepLinkPathPages.ACCOUNT_CARI_PRIZE_SCREEN]: 'account_cari_prize_screen',
	[EDeepLinkPathPages.ACCOUNT_LANGUAGE_SCREEN]: 'account_language_screen',
	[EDeepLinkPathPages.ACCOUNT_ABOUT_US_SCREEN]: 'account_about_us_screen',
	// Cart
	[EDeepLinkPathPages.CART]: 'cart',
	// WebView
	[EDeepLinkPathPages.WEBVIEW]: 'webview',
};

// ! DEPP LINK MAP
export const DEEP_LINK_CONFIG_MAP: Record<EDeepLinkPathPages, IDeepLinkConfig> = {
	// Home
	[EDeepLinkPathPages.HOME_SCREEN]: {
		path: EDeepLinkPathPages.HOME_SCREEN,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.HOME_SCREEN],
	},
	// All Services
	[EDeepLinkPathPages.ALL_SERVICES_SCREEN]: {
		path: EDeepLinkPathPages.ALL_SERVICES_SCREEN,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.ALL_SERVICES_SCREEN],
	},
	// Hub Specific
	[EDeepLinkPathPages.HUB_SCREEN_GENERIC_SPECIFIC_VERTICAL]: {
		path: EDeepLinkPathPages.HUB_SCREEN_GENERIC_SPECIFIC_VERTICAL,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.HUB_SCREEN_GENERIC_SPECIFIC_VERTICAL],
		params: [
			{
				key: 'verticalId',
				slug: 'vertical_id',
				InputEl: (props) => (
					<VerticalsSelect
						onlyActive
						types={[EVerticalType.INDEPENDENT, EVerticalType.PARENT]}
						{...props}
					/>
				),
			},
		],
	},
	[EDeepLinkPathPages.HUB_SCREEN_GENERIC_SPECIFIC_VERTICAL_AND_SUB_VERTICAL]: {
		path: EDeepLinkPathPages.HUB_SCREEN_GENERIC_SPECIFIC_VERTICAL_AND_SUB_VERTICAL,
		translationKey:
			DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[
				EDeepLinkPathPages.HUB_SCREEN_GENERIC_SPECIFIC_VERTICAL_AND_SUB_VERTICAL
			],
		params: [
			{
				key: 'verticalId',
				slug: 'vertical_id',
				InputEl: (props) => (
					<VerticalsSelect
						onlyActive
						types={[EVerticalType.PARENT]}
						{...props}
					/>
				),
			},
			{
				key: 'childVerticalId',
				slug: 'child_verticalId_id',
				InputEl: DefaultInputNumber,
			},
		],
	},
	// ? TEMP HIDE
	// [EDeepLinkPathPages.HUB_SCREEN_BOX_DELIVERY]: {
	// 	path: EDeepLinkPathPages.HUB_SCREEN_BOX_DELIVERY,
	// 	translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.HUB_SCREEN_BOX_DELIVERY],
	// },
	// ? --------

	// Store Details
	[EDeepLinkPathPages.STORE_DETAILS_BY_ID]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_ID,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.STORE_DETAILS_BY_ID],
		params: [
			{
				key: 'storeId',
				slug: 'store_id',
				InputEl: StoresSelector,
			},
		],
	},
	[EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID],
		params: [
			{
				key: 'storeId',
				slug: 'store_id',
				InputEl: StoresSelector,
			},
			{
				key: 'menuId',
				slug: 'menu_id',
				InputEl: DefaultInputNumber,
			},
		],
	},
	[EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID_AND_CATEGORY_ID]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID_AND_CATEGORY_ID,
		translationKey:
			DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID_AND_CATEGORY_ID],
		params: [
			{
				key: 'storeId',
				slug: 'store_id',
				InputEl: StoresSelector,
			},
			{
				key: 'menuId',
				slug: 'menu_id',
				InputEl: DefaultInputNumber,
			},
			{
				key: 'categoryId',
				slug: 'category_id',
				InputEl: DefaultInputNumber,
			},
		],
	},
	[EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID,
		translationKey:
			DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[
				EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID
			],
		params: [
			{
				key: 'storeId',
				slug: 'store_id',
				InputEl: StoresSelector,
			},
			{
				key: 'menuId',
				slug: 'menu_id',
				InputEl: DefaultInputNumber,
			},
			{
				key: 'categoryId',
				slug: 'category_id',
				InputEl: DefaultInputNumber,
			},
			{
				key: 'productId',
				slug: 'product_id',
				InputEl: DefaultInputNumber,
			},
		],
	},
	[EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_PURPOSE]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_PURPOSE,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_PURPOSE],
		params: [
			{
				key: 'storeId',
				slug: 'store_id',
				InputEl: StoresSelector,
			},
			{
				key: 'menuPurpose',
				slug: 'menu_purpose',
				InputEl: MenuPurposeSelect,
			},
		],
	},
	[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE],
		params: [
			{
				key: 'regCode',
				slug: 'reg_code',
				InputEl: RegCodeInputText,
			},
		],
	},
	[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID,
		translationKey:
			DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID],
		params: [
			{
				key: 'regCode',
				slug: 'reg_code',
				InputEl: RegCodeInputText,
			},
			{
				key: 'menuId',
				slug: 'menu_id',
				InputEl: DefaultInputNumber,
			},
		],
	},
	[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID_AND_CATEGORY_ID]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID_AND_CATEGORY_ID,
		translationKey:
			DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[
				EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID_AND_CATEGORY_ID
			],
		params: [
			{
				key: 'regCode',
				slug: 'reg_code',
				InputEl: RegCodeInputText,
			},
			{
				key: 'menuId',
				slug: 'menu_id',
				InputEl: DefaultInputNumber,
			},
			{
				key: 'categoryId',
				slug: 'category_id',
				InputEl: DefaultInputNumber,
			},
		],
	},
	[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID,
		translationKey:
			DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[
				EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID
			],
		params: [
			{
				key: 'regCode',
				slug: 'reg_code',
				InputEl: RegCodeInputText,
			},
			{
				key: 'menuId',
				slug: 'menu_id',
				InputEl: DefaultInputNumber,
			},
			{
				key: 'categoryId',
				slug: 'category_id',
				InputEl: DefaultInputNumber,
			},
			{
				key: 'productId',
				slug: 'product_id',
				InputEl: DefaultInputNumber,
			},
		],
	},
	[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_PURPOSE]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_PURPOSE,
		translationKey:
			DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_PURPOSE],
		params: [
			{
				key: 'regCode',
				slug: 'reg_code',
				InputEl: RegCodeInputText,
			},
			{
				key: 'menuPurpose',
				slug: 'menu_purpose',
				InputEl: MenuPurposeSelect,
			},
		],
	},
	[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID],
		params: [
			{
				key: 'vendorId',
				slug: 'vendor_id',
				InputEl: VendorsSelector,
			},
		],
	},
	[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID,
		translationKey:
			DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID],
		params: [
			{
				key: 'vendorId',
				slug: 'vendor_id',
				InputEl: VendorsSelector,
			},
			{
				key: 'menuId',
				slug: 'menu_id',
				InputEl: DefaultInputNumber,
			},
		],
	},
	[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID_AND_CATEGORY_ID]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID_AND_CATEGORY_ID,
		translationKey:
			DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[
				EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID_AND_CATEGORY_ID
			],
		params: [
			{
				key: 'vendorId',
				slug: 'vendor_id',
				InputEl: VendorsSelector,
			},
			{
				key: 'menuId',
				slug: 'menu_id',
				InputEl: DefaultInputNumber,
			},
			{
				key: 'categoryId',
				slug: 'category_id',
				InputEl: DefaultInputNumber,
			},
		],
	},
	[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID,
		translationKey:
			DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[
				EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID
			],
		params: [
			{
				key: 'vendorId',
				slug: 'vendor_id',
				InputEl: VendorsSelector,
			},
			{
				key: 'menuId',
				slug: 'menu_id',
				InputEl: DefaultInputNumber,
			},
			{
				key: 'categoryId',
				slug: 'category_id',
				InputEl: DefaultInputNumber,
			},
			{
				key: 'productId',
				slug: 'product_id',
				InputEl: DefaultInputNumber,
			},
		],
	},
	[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_PURPOSE]: {
		path: EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_PURPOSE,
		translationKey:
			DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_PURPOSE],
		params: [
			{
				key: 'vendorId',
				slug: 'vendor_id',
				InputEl: VendorsSelector,
			},
			{
				key: 'menuPurpose',
				slug: 'menu_purpose',
				InputEl: MenuPurposeSelect,
			},
		],
	},
	// Wallet
	[EDeepLinkPathPages.WALLET]: {
		path: EDeepLinkPathPages.WALLET,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.WALLET],
	},
	// Offers
	[EDeepLinkPathPages.OFFERS]: {
		path: EDeepLinkPathPages.OFFERS,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.OFFERS],
	},
	[EDeepLinkPathPages.OFFERS_BY_OFFER_TYPE]: {
		path: EDeepLinkPathPages.OFFERS_BY_OFFER_TYPE,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.OFFERS_BY_OFFER_TYPE],
		params: [
			{
				key: 'offerType',
				slug: 'offer_type',
				InputEl: CustomerStoreOfferTypeSelect,
			},
		],
	},
	[EDeepLinkPathPages.OFFERS_BY_OFFER_TYPE_AND_OFFER_LABEL_ID]: {
		path: EDeepLinkPathPages.OFFERS_BY_OFFER_TYPE_AND_OFFER_LABEL_ID,
		translationKey:
			DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.OFFERS_BY_OFFER_TYPE_AND_OFFER_LABEL_ID],
		params: [
			{
				key: 'offerType',
				slug: 'offer_type',
				InputEl: CustomerStoreOfferTypeSelect,
			},
			{
				key: 'offerLabelId',
				slug: 'offer_label_id',
				InputEl: DefaultInputNumber,
			},
		],
	},
	// Account
	[EDeepLinkPathPages.ACCOUNT_SCREEN]: {
		path: EDeepLinkPathPages.ACCOUNT_SCREEN,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.ACCOUNT_SCREEN],
	},
	[EDeepLinkPathPages.ACCOUNT_FAVORITES_SCREEN]: {
		path: EDeepLinkPathPages.ACCOUNT_FAVORITES_SCREEN,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.ACCOUNT_FAVORITES_SCREEN],
	},
	[EDeepLinkPathPages.ACCOUNT_ADDRESSES_SCREEN]: {
		path: EDeepLinkPathPages.ACCOUNT_ADDRESSES_SCREEN,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.ACCOUNT_ADDRESSES_SCREEN],
	},
	[EDeepLinkPathPages.ACCOUNT_CARDS_SCREEN]: {
		path: EDeepLinkPathPages.ACCOUNT_CARDS_SCREEN,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.ACCOUNT_CARDS_SCREEN],
	},
	[EDeepLinkPathPages.ACCOUNT_CARI_UNLIMITED_SCREEN]: {
		path: EDeepLinkPathPages.ACCOUNT_CARI_UNLIMITED_SCREEN,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.ACCOUNT_CARI_UNLIMITED_SCREEN],
	},
	[EDeepLinkPathPages.ACCOUNT_CARI_PRIZE_SCREEN]: {
		path: EDeepLinkPathPages.ACCOUNT_CARI_PRIZE_SCREEN,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.ACCOUNT_CARI_PRIZE_SCREEN],
	},
	[EDeepLinkPathPages.ACCOUNT_LANGUAGE_SCREEN]: {
		path: EDeepLinkPathPages.ACCOUNT_LANGUAGE_SCREEN,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.ACCOUNT_LANGUAGE_SCREEN],
	},
	[EDeepLinkPathPages.ACCOUNT_ABOUT_US_SCREEN]: {
		path: EDeepLinkPathPages.ACCOUNT_ABOUT_US_SCREEN,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.ACCOUNT_ABOUT_US_SCREEN],
	},
	// Cart
	[EDeepLinkPathPages.CART]: {
		path: EDeepLinkPathPages.CART,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.CART],
	},
	// WebView
	[EDeepLinkPathPages.WEBVIEW]: {
		path: EDeepLinkPathPages.WEBVIEW,
		translationKey: DEEP_LINK_PATH_TRANSLATION_KEY_CONFIG[EDeepLinkPathPages.WEBVIEW],
		params: [
			{
				key: 'redirectionType',
				slug: 'redirection_type',
				InputEl: WebViewRedirectionTypSelect,
			},
			{
				key: 'link',
				slug: 'link',
				InputEl: WebViewLinkInputText,
			},
		],
	},
};

// ! SELECT OPTIONS
const getDeepLinkSelectOption = ({ path, translationKey }: IDeepLinkConfig): ISelectOption => ({
	value: path,
	label: (
		<TranslatedLabel
			nameSpace='deep-links'
			i18nKey={`options.${translationKey}`}
		/>
	),
	description: (
		<TranslatedLabel
			nameSpace='deep-links'
			i18nKey={`options.descriptions.${translationKey}`}
		/>
	),
});

export const DEEP_LINK_SELECT_OPTIONS_WITH_GROUPS = [
	{
		label: (
			<TranslatedLabel
				nameSpace='deep-links'
				i18nKey={'options.group_labels.home'}
			/>
		),
		options: [getDeepLinkSelectOption(DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.HOME_SCREEN])],
	},
	{
		label: (
			<TranslatedLabel
				nameSpace='deep-links'
				i18nKey={'options.group_labels.all_services'}
			/>
		),
		options: [getDeepLinkSelectOption(DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.ALL_SERVICES_SCREEN])],
	},
	{
		label: (
			<TranslatedLabel
				nameSpace='deep-links'
				i18nKey={'options.group_labels.hub'}
			/>
		),
		options: [
			getDeepLinkSelectOption(DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.HUB_SCREEN_GENERIC_SPECIFIC_VERTICAL]),
			getDeepLinkSelectOption(
				DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.HUB_SCREEN_GENERIC_SPECIFIC_VERTICAL_AND_SUB_VERTICAL]
			),
			// getDeepLinkSelectOption(DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.HUB_SCREEN_BOX_DELIVERY]), // ? TEMP Hide
		],
	},
	{
		label: (
			<TranslatedLabel
				nameSpace='deep-links'
				i18nKey={'options.group_labels.store'}
			/>
		),
		options: [
			getDeepLinkSelectOption(DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.STORE_DETAILS_BY_ID]),
			getDeepLinkSelectOption(DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID]),
			getDeepLinkSelectOption(
				DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID_AND_CATEGORY_ID]
			),
			getDeepLinkSelectOption(
				DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID]
			),
			getDeepLinkSelectOption(DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.STORE_DETAILS_BY_ID_AND_MENU_PURPOSE]),

			getDeepLinkSelectOption(DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE]),
			getDeepLinkSelectOption(DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID]),
			getDeepLinkSelectOption(
				DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID_AND_CATEGORY_ID]
			),
			getDeepLinkSelectOption(
				DEEP_LINK_CONFIG_MAP[
					EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID
				]
			),
			getDeepLinkSelectOption(
				DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.STORE_DETAILS_BY_CAM_REG_CODE_AND_MENU_PURPOSE]
			),

			getDeepLinkSelectOption(DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID]),
			getDeepLinkSelectOption(DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID]),
			getDeepLinkSelectOption(
				DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID_AND_CATEGORY_ID]
			),
			getDeepLinkSelectOption(
				DEEP_LINK_CONFIG_MAP[
					EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_ID_AND_CATEGORY_ID_AND_PRODUCT_ID
				]
			),
			getDeepLinkSelectOption(
				DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.STORE_DETAILS_BY_VENDOR_ID_AND_MENU_PURPOSE]
			),
		],
	},
	{
		label: (
			<TranslatedLabel
				nameSpace='deep-links'
				i18nKey={'options.group_labels.wallet'}
			/>
		),
		options: [getDeepLinkSelectOption(DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.WALLET])],
	},
	{
		label: (
			<TranslatedLabel
				nameSpace='deep-links'
				i18nKey={'options.group_labels.offers'}
			/>
		),
		options: [
			getDeepLinkSelectOption(DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.OFFERS]),
			getDeepLinkSelectOption(DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.OFFERS_BY_OFFER_TYPE]),
			getDeepLinkSelectOption(DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.OFFERS_BY_OFFER_TYPE_AND_OFFER_LABEL_ID]),
		],
	},
	{
		label: (
			<TranslatedLabel
				nameSpace='deep-links'
				i18nKey={'options.group_labels.account'}
			/>
		),
		options: [
			getDeepLinkSelectOption(DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.ACCOUNT_SCREEN]),
			getDeepLinkSelectOption(DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.ACCOUNT_FAVORITES_SCREEN]),
			getDeepLinkSelectOption(DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.ACCOUNT_ADDRESSES_SCREEN]),
			getDeepLinkSelectOption(DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.ACCOUNT_CARDS_SCREEN]),
			getDeepLinkSelectOption(DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.ACCOUNT_CARI_UNLIMITED_SCREEN]),
			getDeepLinkSelectOption(DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.ACCOUNT_CARI_PRIZE_SCREEN]),
			getDeepLinkSelectOption(DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.ACCOUNT_LANGUAGE_SCREEN]),
			getDeepLinkSelectOption(DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.ACCOUNT_ABOUT_US_SCREEN]),
		],
	},
	{
		label: (
			<TranslatedLabel
				nameSpace='deep-links'
				i18nKey={'options.group_labels.cart'}
			/>
		),
		options: [getDeepLinkSelectOption(DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.CART])],
	},
	{
		label: (
			<TranslatedLabel
				nameSpace='deep-links'
				i18nKey={'options.group_labels.webview'}
			/>
		),
		options: [getDeepLinkSelectOption(DEEP_LINK_CONFIG_MAP[EDeepLinkPathPages.WEBVIEW])],
	},
];
