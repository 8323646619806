import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';
import { useAuth, useConfirmModal } from 'hooks';
import useBanner from 'hooks/useBanner';
import { APP_PERMISSIONS } from 'configs/permissions';
import MoreActions from 'components/MoreActions';
import { MenuProps } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import { DeleteOutlined } from '@ant-design/icons';

export const BannerMoreActions: FC = memo(() => {
	const navigate = useNavigate();
	const { openConfirmModal } = useConfirmModal();

	const { t: tBanners } = useTranslation('banners');
	const { t: tCommon } = useTranslation('common');

	const { hasPermission } = useAuth();

	const { bannerId, data, handleDelete } = useBanner();

	const canDelete = hasPermission(APP_PERMISSIONS.app_management.banners.delete);

	// ! handlers
	const onDeleteSuccess = () => {
		if (!data) return;

		navigate(generatePath('../..'));
	};

	const onDelete = () => {
		if (!data) return;

		openConfirmModal({
			title: tBanners(`delete_modal.confirmation_message_${data.type}`, {
				id: bannerId,
			}),
			onOk: () => handleDelete(+bannerId, onDeleteSuccess),
		});
	};

	const menuItems = useMemo<ItemType[]>(
		() => {
			const items = [
				{
					key: 'action_buttons.delete',
					label: tCommon('action_buttons.delete'),
					icon: <DeleteOutlined />,
					disabled: !canDelete,
					onClick: () => onDelete(),
				},
			];

			return items;
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[canDelete]
	);

	const menu: MenuProps = {
		items: menuItems,
	};

	// ! render
	return <MoreActions menu={menu} />;
});
