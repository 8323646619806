import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useCustomSearchParams } from 'hooks';
import { DEFAULT_USER_INTERACTION_DEBOUNCE, debounce } from 'utils/debounce';
import { isNumber } from 'utils/validationUtils/isNumber';
import { ICustomerListItemResponse } from 'types/api';
import { ICustomersTableData } from './types';
import TranslatedLabel from 'components/TranslatedLabel';
import { App, Input } from 'antd';
import { ColumnType } from 'antd/es/table';
import { FilterDropdownProps } from 'antd/es/table/interface';
import { SearchOutlined } from '@ant-design/icons';

// ! data format
export const transformICustomerListItemToTableData = (customer: ICustomerListItemResponse): ICustomersTableData => {
	return {
		key: customer.id,
		...customer,
	};
};

export const validateNumericSearchInput = (value: string) => {
	if (!isNumber(+value)) {
		return Promise.reject(<TranslatedLabel i18nKey={'search.filters.numeric_error_message'} />);
	}
	return Promise.resolve();
};

interface IFilterProps<T> {
	dataIndex: keyof T | string;
	inputPlaceholderTranslationKey: string;
	validationFunction?: (value: string) => Promise<void>;
}
const filterProps = <T,>({
	dataIndex,
	inputPlaceholderTranslationKey,
	validationFunction = () => Promise.resolve(),
}: IFilterProps<T>): ColumnType<T> => {
	const FilterIcon = () => {
		const [, groupedParams] = useCustomSearchParams();

		const hasParam = groupedParams[dataIndex]?.toString()?.length;

		return <SearchOutlined style={{ color: hasParam ? 'var(--ant-primary-color)' : undefined }} />;
	};

	const FilterDropdown: FC<FilterDropdownProps> = ({ confirm, setSelectedKeys }) => {
		const { message } = App.useApp();
		const { t: tCommon } = useTranslation();

		const [searchParams, groupedParams, setSearchParams] = useCustomSearchParams();

		// ! handlers
		const handleSearch = ({ target }: ChangeEvent<HTMLInputElement>) => {
			const trimmedValue = target.value?.trim();
			const trimmedStringValueLength = trimmedValue.length;

			const errorMessageKey = 'filter-key-message';

			if (!trimmedStringValueLength) {
				onClear();
				return;
			}

			validationFunction(trimmedValue)
				.then(() => {
					setSelectedKeys(trimmedStringValueLength ? [target.value] : []);
					confirm({ closeDropdown: false });

					message.destroy(errorMessageKey);
				})
				.catch((reason) => {
					message.error({ content: reason, key: errorMessageKey });
				});
		};
		const debouncedHandleSearch = debounce(handleSearch, DEFAULT_USER_INTERACTION_DEBOUNCE);

		const onClear = () => {
			if (searchParams.has(dataIndex as string)) {
				searchParams.delete(dataIndex as string);
				setSearchParams(searchParams);
			}
		};

		// ! render
		return (
			<Input
				placeholder={tCommon(inputPlaceholderTranslationKey)}
				onChange={debouncedHandleSearch}
				allowClear
				onClear={onClear}
				autoFocus
				defaultValue={groupedParams[dataIndex]}
			/>
		);
	};
	return {
		filterSearch: true,
		filterDropdown: FilterDropdown,
		filterIcon: FilterIcon,
		filteredValue: null,
	};
};

export { filterProps };
