import { LocalTimeDate } from 'hooks/useLocaleTimeUtils';
import { filterProps, validateNumericSearchInput } from './helper';
import { DATE_FORMAT } from 'utils/date';
import { ID_COLUMN_WIDTH } from 'configs/common';
import { ECustomerStatus, ECustomerStoreOfferType } from 'types/api';
import { IFilterItem, ISelectOption } from 'types/common';
import { ICustomersTableData } from './types';
import TranslatedLabel from 'components/TranslatedLabel';
import { ColumnsType } from 'antd/es/table';

export const CUSTOMER_STORE_OFFER_TYPE_SELECT_OPTIONS: ISelectOption[] = Object.values(ECustomerStoreOfferType).map(
	(value) => ({
		value,
		label: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey={`shared.enum_customer_store_offer_type.${value}`}
			/>
		),
	})
);

export const CUSTOMER_STATUS_FILTER_CONFIG: IFilterItem[] = [
	{
		value: ECustomerStatus.ACTIVE,
		text: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey={`shared.status_labels.${ECustomerStatus.ACTIVE}`}
			/>
		),
	},
	{
		value: ECustomerStatus.BLOCKED,
		text: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey={`shared.status_labels.${ECustomerStatus.BLOCKED}`}
			/>
		),
	},
	{
		value: ECustomerStatus.DELETED,
		text: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey={`shared.status_labels.${ECustomerStatus.DELETED}`}
			/>
		),
	},
];

export const DEFAULT_CUSTOMERS_TABLE_COLUMNS: ColumnsType<ICustomersTableData> = [
	{
		key: 'id',
		title: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey={`shared.entity.id`}
			/>
		),
		dataIndex: 'id',
		width: ID_COLUMN_WIDTH,
		...filterProps({
			dataIndex: 'id',
			inputPlaceholderTranslationKey: 'search_by.id',
			validationFunction: validateNumericSearchInput,
		}),
	},
	{
		key: 'name',
		title: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey={`shared.entity.name`}
			/>
		),
		ellipsis: true,
		render: (_, { first_name, last_name }) => `${first_name} ${last_name}`,
	},
	{
		key: 'phone_number',
		title: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey={'shared.entity.phone_number'}
			/>
		),
		width: 180,
		render: (_, { phone_number, country_code }) => `${country_code} ${phone_number}`,
		...filterProps({
			dataIndex: 'phone_number',
			inputPlaceholderTranslationKey: 'search_by.phone_number',
		}),
	},
	{
		key: 'email',
		title: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey={'shared.entity.email'}
			/>
		),
		width: 180,
		render: (_, { email }) => `${email}`,
		...filterProps({
			dataIndex: 'email',
			inputPlaceholderTranslationKey: 'search_by.email',
		}),
	},
	{
		key: 'created_at',
		title: (
			<TranslatedLabel
				nameSpace='customers'
				i18nKey={`shared.entity.created_at`}
			/>
		),
		width: 140,
		sorter: true,
		render: (_, { created_at }) => (
			<LocalTimeDate
				date={created_at}
				format={DATE_FORMAT}
			/>
		),
	},
];
