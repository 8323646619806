import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks';
import { APP_PERMISSIONS } from 'configs/permissions';
import { useAddEditBannerContext } from 'pages/Banners/AddEditBannerProvider';
import { EBannerTypeTabs } from 'pages/Banners/types';
import { SegmentedViewModeToggler } from 'pages/Banners/useViewMode';
import { Button } from 'antd';

export const BannerAddCommands: FC<{ type: EBannerTypeTabs }> = ({ type }) => {
	const { hasPermission } = useAuth();

	const { t: tBanners } = useTranslation('banners');

	const { openModal, refreshingData } = useAddEditBannerContext();

	// ! handler
	const onAddBannerBtnClick = () => {
		openModal(refreshingData, type);
	};

	// ! render
	const buttonLabel =
		type === EBannerTypeTabs.PROMOTIONAL_SWIMLANE
			? tBanners('add_banner_swimlane.button')
			: tBanners('add_banner.button');

	return (
		<>
			<SegmentedViewModeToggler />

			<Button
				key='add_banner_btn'
				type='primary'
				onClick={onAddBannerBtnClick}
				disabled={!hasPermission(APP_PERMISSIONS.app_management.banners.add)}
			>
				{buttonLabel}
			</Button>
		</>
	);
};
