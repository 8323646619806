import { FC, ReactNode, memo, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth, useConfirmModal, useNewHttpClient } from 'hooks';
import { transformBannersDataToTableData } from './helpers';
import { BANNERS_API } from 'configs/api';
import { STATUS_FILTERS_CONFIG } from 'configs/common';
import { APP_PERMISSIONS } from 'configs/permissions';
import { TABLE_CONFIGS_BY_E_BANNER_TYPE } from './configs';
import { EBannerType, IBanner } from 'types/api';
import { EStatus, Nullable } from 'types/common';
import { IBannerListProps, IBannersTableData } from './types';
import ActionsMenu, { IActionMenuItem, getActionButtonsColumnWidth } from 'components/ActionsMenu';
import StatusSwitch from 'components/StatusSwitch';
import TableWrapper from 'components/Table';
import { useAddEditBannerContext } from 'pages/Banners/AddEditBannerProvider';
import BannerUploadImgModal from 'pages/Banners/components/BannerUploadImgModal';
import HighlightedBannersPreview from 'pages/Banners/components/HighlightedBannersPreview';
import PromotionalBannersPreview from 'pages/Banners/components/PromotionalBannersPreview';
import useUpdateBannerStatus from 'pages/Banners/hooks/useUpdateBannerStatus';
import { EBannerTypeTabs } from 'pages/Banners/types';
import { EListViewMode, useViewMode } from 'pages/Banners/useViewMode';
import { Flex } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { DeleteOutlined, EyeOutlined, FileImageOutlined } from '@ant-design/icons';

const BannerList: FC<IBannerListProps> = ({ type, tableExtra }) => {
	const { t: tBanners } = useTranslation('banners');
	const { t: tCommon } = useTranslation('common');

	const { hasPermission } = useAuth();
	const { openConfirmModal } = useConfirmModal();
	const { viewMode } = useViewMode();

	const { openModal, refreshingData, forceRefreshData } = useAddEditBannerContext();
	const { onActivateBanner, onDeactivateBanner } = useUpdateBannerStatus(refreshingData);

	const requestType = useMemo(() => {
		return type === (EBannerTypeTabs.PROMOTIONAL_SWIMLANE as string)
			? [EBannerType.PROMOTIONAL, EBannerType.SWIMLANE]
			: type;
	}, [type]);

	const bannersListConfig = useMemo(() => BANNERS_API.list(undefined, { type: requestType }), [requestType]);

	// ! http clients
	const deleteBannerHttpClient = useNewHttpClient();

	// ! states
	const [uploadingBannerImgId, setUploadingBannerImgId] = useState<Nullable<number>>(null);

	// ! handlers
	const onEditBanner = (banner: IBannersTableData | IBanner) => {
		openModal(refreshingData, type, banner);
	};

	const onDeleteBanner = (id: number) => {
		deleteBannerHttpClient.request({
			requestConfig: BANNERS_API.delete(id),
			successCallback: () => {
				refreshingData();
			},
		});
	};

	const onCloseBannerUploadImgModal = (refresh: boolean) => {
		setUploadingBannerImgId(null);
		if (refresh) refreshingData();
	};

	// ! table columns
	const tableColumns = useMemo<ColumnsType<IBannersTableData>>(() => {
		const canEdit = hasPermission(APP_PERMISSIONS.app_management.banners.update);
		const canDelete = hasPermission(APP_PERMISSIONS.app_management.banners.delete);
		const canActivate = hasPermission(APP_PERMISSIONS.app_management.banners.activate);
		const canDeactivate = hasPermission(APP_PERMISSIONS.app_management.banners.deactivate);

		const defaultTable = TABLE_CONFIGS_BY_E_BANNER_TYPE[type] ?? [];

		return [
			...defaultTable,
			{
				key: 'status',
				title: tBanners('entity.status'),
				width: 100,
				render: (_, record) => (
					<StatusSwitch
						status={record.status}
						disabled={!canActivate && !canDeactivate}
						onChange={() => {
							if (record.status === EStatus.ACTIVE) {
								onDeactivateBanner(record.id);
							} else {
								onActivateBanner(record.id);
							}
						}}
					/>
				),
				filterMultiple: false,
				filters: STATUS_FILTERS_CONFIG,
			},
			{
				key: 'actions',
				title: tBanners('entity.actions'),
				width: getActionButtonsColumnWidth(2),
				fixed: 'right',
				render: (_, record) => {
					const isSwimlane = record.type === EBannerType.SWIMLANE;
					const actions: IActionMenuItem[] = [
						// IMAGE
						{
							type: 'button',
							title: tBanners('action_buttons.upload_image'),
							icon: <FileImageOutlined />,
							disabled: !canEdit || isSwimlane,
							actionCb: () => setUploadingBannerImgId(record.id),
							moreAction: true,
						},
						// VIEW
						{
							type: 'link',
							title: tCommon('action_buttons.view'),
							icon: <EyeOutlined />,
							navigateTo: `./${record.id}/details`,
						},
						// DELETE
						{
							type: 'button',
							title: tCommon('action_buttons.delete'),
							icon: <DeleteOutlined />,
							disabled: !canDelete,
							actionCb: () =>
								openConfirmModal({
									title: tBanners(`delete_modal.confirmation_message_${record.type}`, {
										id: record.id,
									}),
									onOk: () => onDeleteBanner(record.id),
								}),
							moreAction: true,
						},
					];
					return (
						<ActionsMenu
							actions={actions}
							withMoreActions
						/>
					);
				},
			},
		];
	}, [hasPermission]); // eslint-disable-line react-hooks/exhaustive-deps

	// ! render
	const PREVIEW_ELEMENT: Record<EBannerTypeTabs, ReactNode> = {
		[EBannerTypeTabs.HIGHLIGHTED_HOME]: <HighlightedBannersPreview type={type} />,
		[EBannerTypeTabs.HIGHLIGHTED_ACCOUNT]: <HighlightedBannersPreview type={type} />,
		[EBannerTypeTabs.PROMOTIONAL_SWIMLANE]: (
			<PromotionalBannersPreview
				forceRefreshData={forceRefreshData}
				onEdit={(banner) => onEditBanner(banner as IBanner)}
			/>
		),
	};

	return (
		<div id='list-content'>
			{/* PREVIEW MODE */}
			{viewMode === EListViewMode.PREVIEW && (
				<Flex
					gap='middle'
					vertical
				>
					<Flex
						justify='end'
						gap='small'
					>
						{tableExtra}
					</Flex>
					{PREVIEW_ELEMENT[type]}
				</Flex>
			)}

			{/* TABLE MODE */}
			{viewMode === EListViewMode.TABLE && (
				<TableWrapper<IBannersTableData, IBanner>
					scrollX={'fit-content'}
					columns={tableColumns}
					defaultControlSizes='middle'
					refetchData={forceRefreshData}
					requestConfig={bannersListConfig}
					searchPlaceholder={tBanners('placeholders.search')}
					transformDataToTableData={transformBannersDataToTableData}
					tableExtraActions={tableExtra}
				/>
			)}

			{/* BANNER IMG EDIT MODAL */}
			<BannerUploadImgModal
				bannerId={uploadingBannerImgId}
				onClose={onCloseBannerUploadImgModal}
			/>
		</div>
	);
};

export default memo(BannerList);
